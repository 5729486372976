import React, { useEffect, useState } from 'react';
import { TitleCard, RadioGroup, Switch, FieldText } from '../../ui';
import {
  Compliance,
  ComplianceFile,
  ComplianceMetaData,
  HostedFile,
} from '../../../backend/careo-api';
import ComplianceUpload from '../compliance-edit/compliance-upload';

type REGISTRATION_DOCUMENTSProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: ComplianceMetaData,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey =
  | 'applicationFormFiles'
  | 'handbookFiles'
  | 'interviewFormFiles'
  | 'appraisalsFiles'
  | 'ecsFiles';

type TAllFields =
  | TFilesKey
  | 'isHandbookSent'
  | 'emergencyName'
  | 'emergencyPhoneNumber'
  | 'emergencyRelationship';

export const RegistrationDocuments = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: REGISTRATION_DOCUMENTSProps) => {
  const [isApproved, setIsApproved] = useState<boolean>();
  const [isHandbookSent, setIsHandbookSent] = useState<boolean>();
  const [emergencyName, setEmergencyName] = useState<string>();
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState<string>();
  const [emergencyRelationship, setEmergencyRelationship] = useState<string>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; data: ComplianceFile[] }[]
  >([
    { key: 'applicationFormFiles', data: [] },
    { key: 'handbookFiles', data: [] },
  ]);

  const onUpdateCompliance = (
    key: TAllFields,
    value: any,
    successMessage?: string,
  ) => {
    onClickSubmit(
      compliance._id,
      {
        [key]: value,
      },
      false,
      successMessage,
    );
  };

  useEffect(() => {
    if (compliance?._id) {
      setIsHandbookSent(compliance.metadata?.isHandbookSent ?? false);
      setFilesList([
        {
          key: 'applicationFormFiles',
          data: compliance?.metadata?.applicationFormFiles ?? [],
        },
        {
          key: 'handbookFiles',
          data: compliance?.metadata?.handbookFiles ?? [],
        },
        {
          key: 'interviewFormFiles',
          data: compliance?.metadata?.interviewFormFiles ?? [],
        },
        {
          key: 'appraisalsFiles',
          data: compliance?.metadata?.appraisalsFiles ?? [],
        },
        {
          key: 'ecsFiles',
          data: compliance?.metadata?.ecsFiles ?? [],
        },
      ]);
      setEmergencyName(compliance.metadata?.emergencyName);
      setEmergencyPhoneNumber(compliance.metadata?.emergencyPhoneNumber);
      setEmergencyRelationship(compliance.metadata?.emergencyRelationship);
      setIsApproved(compliance.isApproved);
    }
  }, [compliance]);

  return (
    <>
      {/* Application form Section */}
      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Application form
        </TitleCard>
        <ComplianceUpload
          fileKey="applicationFormFiles"
          label="Upload document"
          description="Does it contain all required fields."
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
      </>
      {/* Company Handbook Section */}
      <TitleCard data-testid="title-card-handbook-update-service">
        Company Handbook
      </TitleCard>

      <div className="question-toggle-container">
        <div className="label">
          Has the company handbook been sent to the candidate
        </div>

        <div>{isHandbookSent ? 'Yes' : 'No'}</div>

        <div className="action-toggle">
          <Switch
            value={isHandbookSent ?? false}
            onClick={() => {
              onUpdateCompliance('isHandbookSent', !isHandbookSent);
              setIsHandbookSent((prev) => !prev);
            }}
          />
        </div>
      </div>

      <ComplianceUpload
        fileKey="handbookFiles"
        label="Upload handbook"
        description="Does it contain all required fields."
        getDocuments={getDocuments}
        reFetchCompliance={reFetchCompliance}
        filesList={filesList}
        setFilesList={setFilesList}
        onUpdateCompliance={onUpdateCompliance}
        documents={documents}
      />

      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Interview Form
        </TitleCard>
        <ComplianceUpload
          fileKey="interviewFormFiles"
          label="Upload document"
          description="Does it contain all required fields."
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
      </>

      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Appraisals
        </TitleCard>
        <ComplianceUpload
          fileKey="appraisalsFiles"
          label="Upload document"
          description="Does it contain all required fields."
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
      </>

      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Emergency Contact
        </TitleCard>
        <div className="row">
          <div className="col-md-4">
            <FieldText
              placeholder="Enter here ..."
              label="Name"
              required
              value={emergencyName}
              onChange={(e) => {
                setEmergencyName(e.target.value);
              }}
              onBlur={(e) => {
                onUpdateCompliance('emergencyName', e.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <FieldText
              placeholder="Enter here ..."
              label="Phone Number"
              required
              value={emergencyPhoneNumber}
              onChange={(e) => {
                setEmergencyPhoneNumber(e.target.value);
              }}
              onBlur={(e) => {
                onUpdateCompliance('emergencyName', e.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <FieldText
              placeholder="Enter here ..."
              label="Relationship"
              required
              value={emergencyRelationship}
              onChange={(e) => {
                setEmergencyRelationship(e.target.value);
              }}
              onBlur={(e) => {
                onUpdateCompliance('emergencyRelationship', e.target.value);
              }}
            />
          </div>
        </div>
      </>

      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Employer Checking Service
        </TitleCard>
        <ComplianceUpload
          fileKey="ecsFiles"
          label="Upload document"
          description="Does it contain all required fields."
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
      </>

      {/* Approved Section */}
      <>
        <TitleCard data-testid="title-card-approved">Approved</TitleCard>
        <RadioGroup
          options={[
            {
              label: 'Pass',
              value: 'true',
            },
            {
              label: 'Fail',
              value: 'false',
            },
          ]}
          name="approved"
          value={isApproved?.toString()}
          onChange={(value: any) => onUpdateValidation(compliance._id, value)}
          data-testid="approved-radio-group"
        />
      </>
    </>
  );
};
