import { EChargeRateType, EJobLevel, ENhsPayGrade } from '../backend/careo-api';

const gradesPerChargeRateType = [
  {
    type: EChargeRateType.NursesHealthCareAssistants,
    levels: [EJobLevel.Nurse],
    grades: [
      ENhsPayGrade.Band5,
      ENhsPayGrade.Band6,
      ENhsPayGrade.Band7,
      ENhsPayGrade.Band8A,
      ENhsPayGrade.Band8B,
      ENhsPayGrade.Band8C,
      ENhsPayGrade.Band8D,
      ENhsPayGrade.Band9,
    ],
  },

  {
    type: EChargeRateType.Doctors,
    levels: [EJobLevel.JuniorDoctor, EJobLevel.MiddleGrade],
    grades: [
      ENhsPayGrade.FY1,
      ENhsPayGrade.FY2,
      ENhsPayGrade.ST1,
      ENhsPayGrade.ST2,
      ENhsPayGrade.SeniorHouseOfficer,
      ENhsPayGrade.ST3,
      ENhsPayGrade.ST4,
      ENhsPayGrade.ST5,
      ENhsPayGrade.ST6,
      ENhsPayGrade.ST7,
      ENhsPayGrade.ST8,
      ENhsPayGrade.AssociateSpecialist,
      ENhsPayGrade.StaffGrade,
      ENhsPayGrade.SpecialtyDoctor,
    ],
  },
];

export const getChargeRateType = (grade: ENhsPayGrade, level?: EJobLevel) => {
  return gradesPerChargeRateType.find(({ grades, levels }) => {
    const hasGrade = grades.includes(grade);
    const hasLevel = levels.includes(level!);

    if (level) return hasGrade && hasLevel;
    else return hasGrade;
  })?.type;
};
