import React, { ChangeEvent } from 'react';
import { FieldFileContainer } from './field-file.style';

type FieldFileProps = {
  label?: string;
  placeholder?: string;
  value?: string | number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  register?: any;
  disabled?: boolean;
  multiple?: boolean;
};

export const FieldFile = ({
  label,
  placeholder = '',
  required,
  onChange,
  value,
  register,
  disabled,
  multiple = false,
}: FieldFileProps) => {
  return (
    <FieldFileContainer data-testid="field-file-container">
      {label && (
        <label htmlFor="" data-testid="field-file-label">
          {label} {required && <span>*</span>}
        </label>
      )}
      <input
        type="file"
        multiple={multiple}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...register}
        disabled={disabled}
        data-testid={`${label}-file-input`}
      />
    </FieldFileContainer>
  );
};
