import React, { useMemo } from 'react';
import styled, { keyframes, css } from 'styled-components';
import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  ChartType,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Extend PluginOptionsByType to include customLabels and drawDataValues
declare module 'chart.js' {
  interface PluginOptionsByType<TType extends ChartType> {
    customLabels?: any;
    drawDataValues?: any;
  }
}

ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale);

// Define keyframes for the loader rotation animation
const rotateArc = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const ChartContainer = styled.div<{ size?: number; loading?: boolean }>`
  // display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible; /* Ensure overflowing elements are visible */
  z-index: 10; /* Ensure the chart is not clipped by other elements */

  .chart-details {
    box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
    width: ${({ size = 200 }) => size * 0.5}px;
    height: ${({ size = 200 }) => size * 0.5}px;
    border-radius: ${({ size = 200 }) => size * 0.25}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    z-index: 1;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* Loader Arc */
    ${({ loading }) =>
      loading &&
      css`
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 2px solid transparent;
          border-top-color: #27a376; /* The color of the rotating arc */
          animation: ${rotateArc} 1s linear infinite;
        }
      `}
  }
  .chart-title {
    font-size: 20px;
    text-align: center !important;
  }
  .chart-container {
    z-index: 1;
    position: relative; /* Relative position for stacking context */
  }
  .custom-values {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 14px;
    color: #333;
  }
`;

export type BarChartConfig = {
  labels: string[];
  backgroundColors?: string[];
  borderColors?: string[];
  title?: string;
  subtitle?: string;
  size?: number;
  displayLegend?: boolean;
  displayCenterValues?: boolean;
};

type BarChartProps = {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
    }[];
  };
  config: BarChartConfig;
  onArcClick?: (label: string, value: number) => void;
  selectedItem?: string;
  isLoading?: boolean;
};

// Custom plugin to draw custom labels on the canvas
const customLabelsPlugin = {
  id: 'customLabels',
  beforeDraw: (chart: any) => {
    const ctx = chart.ctx;
    const xAxis = chart.scales.x;
    xAxis?.ticks.forEach((tick: any, index: number) => {
      const x = xAxis.getPixelForTick(index);
      const y = xAxis.bottom + 10;
      ctx.save();
      ctx.textAlign = 'center';
      // ctx.fillText('Week', x, y);
      // ctx.fillText(tick.label, x, y + 30);
      ctx.restore();
    });
  },
};

// Register the custom plugin
ChartJS.register(customLabelsPlugin);

export const BarChart: React.FC<BarChartProps> = ({
  data,
  config: {
    title = 'Chart',
    displayLegend = true,
    displayCenterValues = false,
  },
  isLoading = false,
  selectedItem = '',
}) => {
  const generateUniqueId = () =>
    `chart-${Math.random().toString(36).substr(2, 9)}`;
  const chartId = useMemo(generateUniqueId, []);

  // useEffect(() => {
  //   // Custom plugin to draw data values in the center of each bar
  //   const drawDataValuesPlugin = {
  //     id: 'drawDataValues' + chartId,
  //     afterDatasetsDraw: (chart: any) => {
  //       const ctx = chart.ctx;
  //       chart.data.datasets.forEach((dataset: any, i: number) => {
  //         const meta = chart.getDatasetMeta(i);
  //         meta.data.forEach((bar: any, index: number) => {
  //           let value = dataset.data[index];
  //           value =
  //             Number(value) >= 1000
  //               ? `${(Number(value) / 1000).toFixed(1)}K`
  //               : Number(value) % 1 === 0
  //                 ? Number(value).toString() // Show integer without decimal part
  //                 : Number(value).toFixed(1); // Round to 1 decimal
  //           const x = bar.x;
  //           const y = bar.y + bar.height / 2; // Center the text vertically
  //           ctx.save();
  //           ctx.textAlign = 'center';
  //           ctx.textBaseline = 'middle';
  //           ctx.fillStyle = 'gray';
  //           ctx.translate(x, y);
  //           ctx.rotate(-Math.PI / 2); // Rotate text to vertical
  //           ctx.fillText(value, 0, 0);
  //           ctx.restore();
  //         });
  //       });
  //     },
  //   };
  //   // Conditionally register the drawDataValuesPlugin
  //   if (displayCenterValues) {
  //     ChartJS.register(drawDataValuesPlugin);
  //   } else {
  //     ChartJS.unregister(drawDataValuesPlugin);
  //   }
  // }, [displayCenterValues, chartId]);

  return (
    <ChartContainer>
      <div className="chart-title" data-testid="chart-title">
        {title}
      </div>
      <div className="chart-container" data-testid="chart-visual">
        <Bar
          data={data}
          options={{
            plugins: {
              legend: {
                display: displayLegend,
                align: 'start',
                position: 'bottom',
                labels: {
                  padding: 10,
                  boxWidth: 10,
                },
              },
              customLabels: {}, // Enable the custom plugin
              ...(displayCenterValues ? { drawDataValues: {} } : {}), // Conditionally enable drawDataValues plugin
            },
            animation: {
              duration: 300,
            },
            layout: {
              padding: {
                top: 10,
                bottom: 30, // Add padding between the chart and the legend
              },
            },
            scales: {
              x: {
                stacked: true,
                ticks: {
                  callback: function (value, index, values) {
                    return data.labels[index];
                  },
                },
              },
              y: {
                stacked: true,
                ticks: {
                  callback: function (value, index, values) {
                    return Number(value) >= 1000
                      ? `${Number(value) / 1000}K`
                      : Number(value); // Convert to 'K' for thousands
                  },
                },
              },
            },
            onHover(event, elements, chart) {
              (event?.native?.target as HTMLElement).style.cursor =
                elements?.length > 0 ? 'pointer' : 'auto';
            },
          }}
          data-testid="bar-chart"
        />
      </div>
    </ChartContainer>
  );
};

// export const BarChartPercentage: React.FC<BarChartProps> = ({
//   data,
//   config: {
//     labels,
//     backgroundColors = ['#11af60'],
//     borderColors = ['#11af60'],
//     size = 200,
//   },
//   onArcClick,
//   isLoading = false,
//   selectedItem = '',
// }) => {
//   const totalPercentage = data.reduce((acc, val) => acc + val, 0);
//   const remainPercentage = 100 - totalPercentage;
//   const offsets = labels.map((label) => (label === selectedItem ? 20 : 0));

//   return (
//     <ChartContainer size={size} loading={isLoading}>
//       <div className="chart-container" data-testid="chart-container">
//         <Bar
//           data={{
//             labels: [...labels, 'Empty'],
//             datasets: [
//               {
//                 data: [...data, remainPercentage],
//                 backgroundColor: [...backgroundColors, '#e3e3e3'],
//                 borderColor: [...borderColors, '#e3e3e3'],
//                 borderWidth: 1,
//                 borderRadius: 4,
//               },
//             ],
//           }}
//           options={{
//             plugins: {
//               legend: {
//                 display: false,
//               },
//             },
//             onClick: (event: any, elements: any[]) => {
//               if (elements.length > 0 && onArcClick) {
//                 const index = elements[0].index;
//                 const label = labels[index];
//                 const value = data[index];
//                 onArcClick(label, value);
//               }
//             },
//             animation: {
//               duration: 300, // Duration of the animation
//             },
//             layout: {
//               padding: 10,
//             },
//             onHover(event, elements, chart) {
//               (event?.native?.target as HTMLElement).style.cursor =
//                 elements?.length > 0 ? 'pointer' : 'auto';
//             },
//           }}
//         />
//       </div>
//     </ChartContainer>
//   );
// };
