import { ReportingPage } from '../pages/reporting/reporting.page';
import { IRoute } from '../types/routes.type';
import { ERoute } from '../utils';

export const ReportingRoutes: IRoute[] = [
  {
    path: `/${ERoute.REPORTING}`,
    element: <ReportingPage />,
  },
];
