import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CardContainer,
  Checkbox,
  HeaderPageContainer,
  NewCandidateForm,
  NewSharedCandidatesList,
  Pagination,
  ProgressBar,
  SearchInput,
  SubTitlePage,
  TabFilter,
  Table,
  TitlePage,
  UpdateCandidateForm,
  UserIcon,
} from '../../components';
import {
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  EyeIcon,
  PlusIcon,
  SortIcon,
} from '../../icons';
import {
  getCountiesOfSelectedRegions,
  getGradesOfSelectedLevel,
  jobLevelWithGrades,
  regionsWithCounties,
} from '../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  TFilterCandidate,
  completionOptions,
  complianceStatusOptions,
  filterCandidates,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortCandidates,
} from '../../utils';
import { useNavigate } from 'react-router-dom';
import {
  EComplianceStatus,
  ExtendedCandidate,
  Payroll,
  User,
} from '../../backend/careo-api';
import { toast } from 'react-toastify';
import { ComplianceStatus } from '../compliance/compliance-status.component';
import { MultipleSelect } from '../ui/inputs/select/multiple-select.component';
import { useModal } from '../../contexts/side-modal.context';
import { useConfirm } from '../../contexts/confirm-modal.context';

const itemsPerPage = 8;

export const ComplianceCandidatesList = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [candidates, setCandidates] = useState<ExtendedCandidate[]>([]);
  const [candidatesList, setCandidatesList] = useState<ExtendedCandidate[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [payrollsList, setPayrollsList] = useState<Payroll[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);

  const { openConfirm, closeConfirm } = useConfirm();

  const onDeleteConfirm = (item: ExtendedCandidate) => {
    openConfirm({
      title: 'Delete Candidate',
      component: (
        <>
          Do you want to delete{' '}
          <b data-testid="candidate-to-delete-name">
            {item?.firstName} {item?.lastName}
          </b>
          <div
            style={{
              fontSize: '15px',
              color: '#E6BB20',
            }}
          >
            <span>Note: All Candidate's placements will be removed.</span>
          </div>
        </>
      ),
      onConfirm: () => deleteCandidate(item),
    });
  };

  const { openModal, closeModal } = useModal();
  const onClickCreate = () => {
    openModal({
      title: 'New Candidate',
      component: (
        <NewCandidateForm
          onCancel={() => closeModal()}
          onSuccess={() => {
            getCandidates();
            closeModal();
          }}
          payrollsList={payrollsList}
          usersList={usersList}
          data-testid="new-candidate-form"
        />
      ),
    });
  };

  const onClickEdit = (candidate: ExtendedCandidate) => {
    openModal({
      title: 'Update Candidate',
      component: (
        <UpdateCandidateForm
          onCancel={() => closeModal()}
          onSuccess={() => {
            getCandidates();
            closeModal();
          }}
          payrollsList={payrollsList}
          usersList={usersList}
          selectedCandidate={candidate}
          data-testid="update-candidate-form"
        />
      ),
    });
  };

  const onClickCreateList = () => {
    openModal({
      title: 'Create New List',
      component: (
        <NewSharedCandidatesList
          onCancel={() => closeModal()}
          onSuccess={() => {
            getCandidates();
            closeModal();
          }}
          candidates={candidates}
          users={usersList}
          selectedCandidatesIds={selectedCandidatesIds}
          data-testid="new-shared-candidates-list"
        />
      ),
    });
  };

  const [filter, setFilter] = useState<TFilterCandidate>({
    search: '',
    regions: [],
    counties: [],
    departments: [],
    levels: [],
    grades: [],
    status: [],
    completions: [],
  });

  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const [designationOptions, setDesignationOptions] = useState<string[]>([]);
  const [selectedCandidatesIds, setSelectedCandidatesIds] = useState<string[]>(
    [],
  );

  const onClickCheckboxAll = (value: boolean) => {
    setSelectedCandidatesIds((prev) => {
      if (value) {
        return [...candidatesList.map((el) => el._id)];
      } else return [];
    });
  };

  const onClickCheckbox = (id: string, value: boolean) => {
    setSelectedCandidatesIds((prev) => {
      if (value) {
        prev.push(id);
      } else {
        prev = prev.filter((el) => el !== id);
      }
      return [...prev];
    });
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.CANDIDATES}/${id}`);
  };

  const deleteCandidate = async (candidate: ExtendedCandidate) => {
    await AxiosInstance.candidates
      .candidatesControllerDeleteCandidate(candidate?._id!)
      .then(() => {
        toast.success('Candidate Removed successfully');
        toast.info("All Candidate's placements has been removed successfully");
        getCandidates();
        closeConfirm();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });

    closeConfirm();
  };

  const getPayrolls = async () => {
    AxiosInstance.payrolls
      .payrollsControllerFindAll()
      .then((response) => {
        setPayrollsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerFindAll({})
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCandidates = async () => {
    setIsLoading(true);
    await AxiosInstance.candidates
      .candidatesControllerFindAll({
        analytics: true,
      })
      .then((response) => {
        const result = response.data.items as ExtendedCandidate[];
        setCandidates(result);
        const options = new Set(
          result.map((el) => el.departments?.join(',') ?? ''),
        );
        setDesignationOptions([...options]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(candidatesList, currentPage, itemsPerPage);
  }, [candidatesList, currentPage]);

  useEffect(() => {
    const filteredData = filterCandidates(candidates, filter);
    const sortedData = sortCandidates(filteredData, sort);
    setCandidatesList(sortedData as ExtendedCandidate[]);
    setTotalPages(getNumberOfPages(sortedData.length, itemsPerPage));
  }, [candidates, filter, sort]);

  useEffect(() => {
    getCandidates();
    getPayrolls();
    getUsers();
  }, []);

  return (
    <>
      <TabFilter
        data-testid="tab-filter"
        filters={[
          {
            title: 'Candidates',
            url: ERoute.CANDIDATES,
          },
          {
            title: 'Lists',
            url: ERoute.SHARED_CANDIDATES,
          },
        ]}
      />
      <CardContainer data-testid="card-container">
        <HeaderPageContainer data-testid="header-page-container">
          <div className="left-container">
            <TitlePage data-testid="title-page">Candidates</TitlePage>
            <SubTitlePage data-testid="sub-title-page">
              Manage your Candidate
            </SubTitlePage>
          </div>
          <div className="right-container">
            <Button type="primary" disabled data-testid="download-button">
              <DownloadIcon /> Download
            </Button>
            <Button
              type="primary"
              onClick={() => onClickCreate()}
              data-testid="add-new-button"
            >
              <PlusIcon /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container" data-testid="filter-container">
          <SearchInput
            data-testid="search-input"
            placeholder="Search candidate"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
          />
          <MultipleSelect
            data-testid="region-select"
            placeholder="All Regions"
            options={regionsWithCounties.map((el) => ({
              label: el.region,
              value: el.region,
            }))}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                regions: values ?? [],
              }));
            }}
          />
          <MultipleSelect
            data-testid="county-select"
            placeholder="All Counties"
            options={getCountiesOfSelectedRegions(filter.regions ?? []).map(
              (el) => ({ label: el, value: el }),
            )}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                counties: values ?? [],
              }));
            }}
            disabled={!filter.regions.length && !filter.counties.length}
          />
          <MultipleSelect
            data-testid="specialties-select"
            placeholder="All Specialties"
            options={designationOptions.map((el) => ({ label: el, value: el }))}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                departments: values ?? [],
              }));
            }}
          />
          <MultipleSelect
            data-testid="level-select"
            placeholder="All Levels"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, levels: values ?? [] }));
            }}
            options={jobLevelWithGrades.map((el) => ({
              label: el.level,
              value: el.level,
            }))}
          />
          <MultipleSelect
            data-testid="grade-select"
            placeholder="All Grades"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, grades: values ?? [] }));
            }}
            options={getGradesOfSelectedLevel(filter.levels).map((el) => ({
              label: el,
              value: el,
            }))}
            disabled={!filter.levels.length && !filter.grades.length}
          />
          <MultipleSelect
            data-testid="completion-select"
            placeholder="All Completions"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, completions: values ?? [] }));
            }}
            options={completionOptions}
          />
          <MultipleSelect
            data-testid="status-select"
            placeholder="All Status"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, status: values ?? [] }));
            }}
            options={complianceStatusOptions}
          />
        </div>
        <div
          className="select-checkbox-action-container"
          data-testid="select-checkbox-action-container"
        >
          <div
            className="number-selected-items"
            data-testid="number-selected-items"
          >
            <b>{selectedCandidatesIds.length}</b> Candidates Selected
          </div>
          <div
            className="checkbox-action-buttons-container"
            data-testid="checkbox-action-buttons-container"
          >
            <Button
              type="success"
              onClick={() => onClickCreateList()}
              data-testid="create-list-button"
            >
              <PlusIcon /> Create List
            </Button>
          </div>
        </div>
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table data-testid="candidate-table">
            <thead>
              <tr>
                <th className="checkbox-table" data-testid="checkbox-header">
                  <Checkbox
                    checked={
                      !!selectedCandidatesIds.length &&
                      selectedCandidatesIds.length === candidatesList.length
                    }
                    indeterminate={
                      !!selectedCandidatesIds.length &&
                      selectedCandidatesIds.length < candidatesList.length
                    }
                    onChange={(e) => onClickCheckboxAll(e.target.checked)}
                    data-testid="checkbox-all"
                  />
                </th>
                <th
                  onClick={() => onSelectSort('firstName', setSort)}
                  data-testid="name-sort-header"
                >
                  <div>
                    <label>Name </label>
                    <SortIcon
                      value={sort.key === 'firstName' ? sort.value : ''}
                      data-testid="name-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('designation', setSort)}
                  data-testid="job-title-sort-header"
                >
                  <div>
                    <label>Job title</label>
                    <SortIcon
                      value={sort.key === 'designation' ? sort.value : ''}
                      data-testid="job-title-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('completionPercentage', setSort)}
                  data-testid="completion-sort-header"
                >
                  <div>
                    <label>Completion</label>
                    <SortIcon
                      value={
                        sort.key === 'completionPercentage' ? sort.value : ''
                      }
                      data-testid="completion-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('grade', setSort)}
                  data-testid="grade-sort-header"
                >
                  <div>
                    <label>NHS Pay Grade</label>
                    <SortIcon
                      value={sort.key === 'grade' ? sort.value : ''}
                      data-testid="grade-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('user', setSort)}
                  data-testid="line-manager-sort-header"
                >
                  <div>
                    <label>Line Manager</label>
                    <SortIcon
                      value={sort.key === 'user' ? sort.value : ''}
                      data-testid="line-manager-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('county', setSort)}
                  data-testid="county-sort-header"
                >
                  <div>
                    <label>County</label>
                    <SortIcon
                      value={sort.key === 'county' ? sort.value : ''}
                      data-testid="county-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('region', setSort)}
                  data-testid="region-sort-header"
                >
                  <div>
                    <label>Region</label>
                    <SortIcon
                      value={sort.key === 'region' ? sort.value : ''}
                      data-testid="region-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('complianceStatus', setSort)}
                  data-testid="compliance-status-sort-header"
                >
                  <div>
                    <label>Compliance Status</label>
                    <SortIcon
                      value={sort.key === 'complianceStatus' ? sort.value : ''}
                      data-testid="compliance-status-sort-icon"
                    />
                  </div>
                </th>
                <th data-testid="action-column-header">
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={100}
                    className="text-center"
                    data-testid="loading-row"
                  >
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((candidate) => {
                        return (
                          <tr
                            key={candidate._id}
                            data-testid={`candidate-row-${candidate._id}`}
                          >
                            <td
                              className="checkbox-table"
                              data-testid={`checkbox-${candidate._id}`}
                            >
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  onClickCheckbox(
                                    candidate._id,
                                    e.target.checked,
                                  )
                                }
                                checked={selectedCandidatesIds.some(
                                  (el) => el === candidate._id,
                                )}
                                data-testid={`checkbox-input-${candidate._id}`}
                              />
                            </td>
                            <td data-testid={`candidate-name-${candidate._id}`}>
                              <div className="name-item">
                                <UserIcon
                                  firstName={candidate.firstName}
                                  lastName={candidate.lastName}
                                  entity="candidate"
                                  data-testid={`user-icon-${candidate._id}`}
                                />
                                <div>
                                  <div>
                                    {candidate.firstName} {candidate.lastName}
                                  </div>
                                  <div
                                    className="email"
                                    data-testid={`candidate-email-${candidate._id}`}
                                  >
                                    {candidate.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td
                              data-testid={`candidate-job-title-${candidate._id}`}
                            >
                              <div className="name-item">
                                <div>
                                  <div>{candidate?.designation || '-'}</div>
                                  <div
                                    className="email"
                                    data-testid={`candidate-department-${candidate._id}`}
                                  >
                                    {candidate.departments?.join(', ')}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td
                              data-testid={`candidate-completion-${candidate._id}`}
                            >
                              <div className="completion">
                                <ProgressBar
                                  value={candidate.completionPercentage ?? 0}
                                  data-testid={`completion-progress-${candidate._id}`}
                                />
                              </div>
                            </td>
                            <td
                              data-testid={`candidate-grade-${candidate._id}`}
                            >
                              {candidate?.grade}
                            </td>
                            <td
                              data-testid={`candidate-line-manager-${candidate._id}`}
                            >
                              {candidate?.recruiter?.firstName}{' '}
                              {candidate?.recruiter?.lastName}
                            </td>
                            <td
                              data-testid={`candidate-county-${candidate._id}`}
                            >
                              {candidate.address?.county || '-'}{' '}
                            </td>
                            <td
                              data-testid={`candidate-region-${candidate._id}`}
                            >
                              {candidate.address?.region || '-'}{' '}
                            </td>
                            <td
                              data-testid={`candidate-compliance-status-${candidate._id}`}
                            >
                              <ComplianceStatus
                                value={
                                  candidate.complianceStatus ??
                                  EComplianceStatus.NOT_ACTIVE
                                }
                                data-testid={`candidate-compliance-status-${candidate._id}`}
                              />
                            </td>
                            <td
                              data-testid={`candidate-actions-${candidate._id}`}
                            >
                              <div className="action-item">
                                <div
                                  className="view-icon"
                                  onClick={() => onClickView(candidate._id)}
                                  data-testid={`view-icon-${candidate._id}`}
                                >
                                  <EyeIcon />
                                </div>

                                <div
                                  className="edit-icon"
                                  onClick={() => onClickEdit(candidate)}
                                  data-testid={`edit-icon-${candidate._id}`}
                                >
                                  <EditIcon />
                                </div>

                                <div
                                  className="delete-icon"
                                  onClick={() => onDeleteConfirm(candidate)}
                                  data-testid={`delete-icon-${candidate._id}`}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td
                        colSpan={100}
                        className="text-center"
                        data-testid="no-item-found"
                      >
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>

        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalEntries={candidatesList.length}
          data-testid="pagination"
        />
      </CardContainer>
    </>
  );
};
