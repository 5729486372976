import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AddLegalProjectDto,
  HostedFile,
  Project,
  User,
} from '../../../backend/careo-api';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Button, Select } from '../../ui';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  handleFormsServerErrors,
  uploadProjectFiles,
} from '../../../utils';
import { toast } from 'react-toastify';
import { DocumentsListComponent } from '../../document';
import { LeftArrowIcon, PlusIcon } from '../../../icons';
import { useModal } from '../../../contexts/side-modal.context';
import { legalProjectSchema } from '../../../utils/validators/projects.validator';

type LegalFormStepProps = {
  usersList: User[];
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  createdProject: Project;
  setCreatedProject: Dispatch<SetStateAction<Project | undefined>>;
  getProjects: () => void;
};

export const LegalFormStep = ({
  usersList,
  createdProject,
  setCreatedProject,
  setStep,
  getProjects,
}: LegalFormStepProps) => {
  const { closeModal } = useModal();
  const fileInputRef = useRef<any>(null);
  const [isUploading, setIsUploading] = useState(false);

  const [documents, setDocuments] = useState<HostedFile[]>([]);

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    reset,
    setFocus,
    formState: { errors },
    control,
  } = useForm<AddLegalProjectDto>({
    resolver: yupResolver(legalProjectSchema),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onAddLegalProject = async () => {
    setIsSubmitting(true);
    const values = getValues();

    await AxiosInstance.projects
      .projectsControllerAddLegalProjectId(createdProject._id, values)
      .then((response) => {
        getProjects();
        setCreatedProject(response.data);
        toast.success('Legal Project saved successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  const onClickDelete = async (documentId: string) => {
    await AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getProjectDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  const handleButtonClick = async () => {
    fileInputRef.current.click();
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target?.files ?? []);
    if (selectedFiles.length) {
      setIsUploading(true);
      await onUpload(selectedFiles);
      e.target.value = '';
      setIsUploading(false);
    }
  };

  const onUpload = async (files: File[]) => {
    await uploadProjectFiles(createdProject._id, files)
      .then(() => {
        toast.success('Document uploaded successfully');
        getProjectDocuments();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getProjectDocuments = async () => {
    await AxiosInstance.files
      .filesControllerFindProjectDocuments(createdProject._id)
      .then((result) => {
        setDocuments(result.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (createdProject) {
      reset({
        legalContactId: createdProject.legalContact?._id,
      });
      getProjectDocuments();
    }
  }, [createdProject._id]);

  return (
    <>
      <Select
        placeholder="Enter here ..."
        label="Legal Contact"
        options={usersList.map((el) => ({
          value: el._id,
          label: el.firstName + ' ' + el.lastName,
        }))}
        register={register('legalContactId')}
        control={control}
        required
        error={errors.legalContactId}
        data-testid="legal-contact-select" // Added test ID for Select
      />

      <DocumentsListComponent
        documents={documents}
        getDocuments={getProjectDocuments}
        onClickDelete={onClickDelete}
      />
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleUpload}
        style={{ display: 'none' }}
        disabled={isUploading}
        multiple
      />
      <Button
        onClick={handleButtonClick}
        type="success"
        variant="outlined"
        disabled={isUploading}
        data-testid="add-another-document"
      >
        <PlusIcon /> Add Another Document
      </Button>
      <div className="form-actions stepper">
        <div className="left-container">
          <Button
            type="danger"
            variant="outlined"
            onClick={() => closeModal()}
            data-testid="close"
          >
            Close
          </Button>
        </div>
        <div className="right-container">
          <Button onClick={() => setStep(3)} data-testid="back">
            <LeftArrowIcon /> Back
          </Button>
          <Button
            onClick={handleSubmit(onAddLegalProject)}
            type="success"
            disabled={isSubmitting}
            data-testid="save"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
