import styled from 'styled-components';
import { breakpoint } from '../../constants';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  .form-actions {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    flex: 1;
    align-items: end;
    &.stepper {
      justify-content: space-between;
      .left-container {
        display: flex;
        gap: 20px;
        justify-content: flex-end;
      }
      .right-container {
        display: flex;
        gap: 20px;
        justify-content: flex-end;
      }
    }
  }

  .accordion {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .accordion-item {
      &.primary-item {
        border-radius: 16px;
        border: 1px solid #f1f2f4;
        background: #fff;
        .accordion-button {
          border: unset !important;
          padding: 24px;
        }

        .accordion-body {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }
      &.secondary-item {
        .accordion-button {
          border: unset !important;
          padding: 2px 21px;
        }
        .accordion-body {
          display: flex;
          flex-direction: column;
          gap: 32px;
        }
      }

      .accordion-collapse {
        border: unset;
      }

      .delete-icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e03137;
        gap: 10px;
        border-radius: 10px;
        svg {
          fill: white;
        }
      }
    }
  }

  .row {
    gap: 32px 0;
    .delete-button {
      display: flex;
      flex-direction: column;
      gap: 12px;
      label {
        visibility: hidden;
      }
    }
  }

  .tab-items-list {
    border-bottom: 1px solid #f1f2f4;
  }

  &.view-form {
    .row {
      .col-3,
      .col-12 {
        margin-bottom: 15px;

        .data-title {
          color: #a0aec0;
          font-size: 12px;
          padding-bottom: 5px;
        }

        .data-value {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  .cpms-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  }

  .margin-container {
    position: relative;
    .badge-data {
      position: absolute;
      bottom: 13px;
      right: 30px;
    }
  }

  &.compliance-form {
    .level-options {
      .options-container {
        flex-wrap: wrap;
        .option-container {
          width: 47%;
        }
      }
    }
    table {
      th,
      td {
        padding: 10px;
      }
      th:first-child {
        width: 40px;
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    height: 100vh;

    .form-actions {
      gap: 5px;
    }

    .row {
      gap: 10px;
      .delete-button {
        gap: 12px;
        label {
          display: none;
        }
      }
    }

    .margin-container {
      .badge-data {
        bottom: 6px;
        right: 25px;
      }
    }
  }

  .action-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    > div {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-radius: 10px;
      cursor: pointer;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      svg {
        path {
          fill: white;
        }
      }
      &.view-icon {
        background-color: #27a376;
      }
      &.edit-icon {
        background-color: #5e7cad;
      }
      &.more-icon {
        background-color: #2f78ee;
      }
      &.download-icon {
        background-color: #2f50ee;
      }
      &.delete-icon {
        background-color: #e03137;
      }

      &:hover {
        opacity: 0.9;
      }
      &.disabled {
        cursor: not-allowed;
        color: gray;
        border: none;
        background-color: #e9ecef;
        svg > path {
          fill: #a0aec0;
        }
      }
    }
  }
`;
