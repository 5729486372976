import {
  BriefcaseIcon,
  CalendarIcon,
  DashboardIcon,
  MenuIcon,
  PaymentCardIcon,
  TrendingIcon,
  UploadIcon,
  UsersIcon,
} from '../icons';

import { isCRMApp } from '../environment/app.type';

export const sidebarWidth = isCRMApp
  ? { opened: 68 + 212, closed: 68 }
  : { opened: 0, closed: 0 };

export const sidebarItems = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    route: '/dashboard',
    isHidden: false,
    icon: DashboardIcon,
  },
  // {
  //   key: 'leads',
  //   title: 'Leads',
  //   route: '/leads',
  //   isHidden: false,
  //   icon: TimerIcon,
  // },
  // {
  //   key: 'projects',
  //   title: 'Projects',
  //   route: '/projects',
  //   isHidden: false,
  //   icon: FileIcon,
  // },
  {
    key: 'placements',
    title: 'Placements',
    route: '/placements',
    isHidden: false,
    icon: UploadIcon,
  },
  {
    key: 'vacancies',
    title: 'Vacancies',
    route: '/vacancies',
    isHidden: false,
    icon: MenuIcon,
  },
  {
    key: 'candidates',
    title: 'Candidates',
    route: '/candidates',
    isHidden: false,
    icon: UsersIcon,
  },
  {
    key: 'clients',
    title: 'Clients',
    route: '/clients',
    isHidden: false,
    icon: BriefcaseIcon,
  },
  {
    key: 'calendar',
    title: 'Calendar',
    route: '/calendar',
    isHidden: false,
    icon: CalendarIcon,
  },
  {
    key: 'payrolls',
    title: 'Payroll',
    route: '/payrolls',
    isHidden: false,
    icon: PaymentCardIcon,
  },
  {
    key: 'reporting',
    title: 'Reporting',
    route: '/reporting',
    isHidden: false,
    icon: TrendingIcon,
    isDisabled: false,
  },
];

export const footerSidebarItems = [
  {
    key: 'settings',
    title: 'Settings',
    route: '/settings',
    isHidden: false,
    icon: DashboardIcon,
    isDisabled: false,
  },
];
