import React, { useState } from 'react';
import { Table } from '../ui';
import {
  CheckIcon,
  DownloadIcon,
  EditIcon,
  EyeIcon,
  MinusIcon,
  PlusIcon,
} from '../../icons';
import { ERoute, formatDate } from '../../utils';
import {
  Compliance,
  ComplianceFile,
  EComplianceName,
} from '../../backend/careo-api';
import { isCRMApp } from '../../environment/app.type';
import { useNavigate, useParams } from 'react-router-dom';
import { ComplianceStatus } from './compliance-status.component';
import { downloadFile } from '../../utils';
import { ComplianceDateForm } from './compliance-date-form.component';
import { useModal } from '../../contexts/side-modal.context';

type ComplianceListProps = {
  compliancesList: Compliance[];
  getComplianceList?: () => Promise<void>;
};

const ComplianceRow = ({
  compliance,
  onClickEdit,
  id,
}: {
  compliance: Compliance;
  onClickEdit: any;
  id: string;
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const subItems = (() => {
    switch (compliance.taskName) {
      case EComplianceName.RegistrationDocuments:
        return [
          {
            label: 'Application form',
            value: compliance.metadata?.applicationFormFiles,
          },
          {
            label: 'Company Handbook',
            value: compliance.metadata?.handbookFiles,
          },
        ];
      case EComplianceName.EmploymentHistoryReferences:
        return [
          {
            label: 'CV',
            value: compliance.metadata?.cvFile,
          },
          {
            label: 'References',
            value: compliance.metadata?.references,
          },
        ];
      case EComplianceName.RightToWork:
        return [
          {
            label: 'Passport',
            value: compliance.metadata?.passportFiles,
          },
          {
            label: 'Certificate',
            value: compliance.metadata?.certificateFiles,
          },
          {
            label: 'Visa',
            value: compliance.metadata?.visaFiles,
          },
        ];
      case EComplianceName.CriminalRecordCheck:
        return [
          {
            label: 'DBS Certificate',
            value: compliance.metadata?.dbsCertificateFile,
          },
        ];
      case EComplianceName.IDBadge:
        return [
          {
            label: 'Profile Picture',
            value: compliance.metadata?.profilePictureFile,
          },
          {
            label: 'ID badge',
            value: compliance.metadata?.badgeFile,
          },
        ];
      case EComplianceName.ProfessionalRegistration:
        return [
          {
            label: 'Registration Certificates',
            value: compliance.metadata?.registrationCertificates,
          },
          {
            label: 'Registration Checks Files',
            value: compliance.metadata?.registrationChecksFiles,
          },
        ];
      case EComplianceName.OccupationalHealthClearance:
        return [
          {
            label: 'Fitness to work certificate',
            value: compliance.metadata?.fitnessToWorkCertificateFile,
          },
          {
            label: 'Bloods',
            value: compliance.metadata?.bloodsFile,
          },
          {
            label: 'Medical Questionnaire',
            value: compliance.metadata?.medicalQuestionnaireFile,
          },
        ];
      case EComplianceName.MandatoryTraining:
        return [
          {
            label: 'Training Certificate',
            value: compliance.metadata?.trainingCertificateFile,
          },
          {
            label: 'Coverage Certificate',
            value: compliance.metadata?.coverageCertificateFile,
          },
        ];
      default:
        return [];
    }
  })();

  return (
    <>
      <tr key={compliance._id} data-testid={`compliance-row-${compliance._id}`}>
        <td onClick={() => setIsOpen((prev) => !prev)} className="toggle-td">
          {isOpen ? <MinusIcon /> : <PlusIcon />}
        </td>
        <td>
          <div
            className={`task-container`}
            data-testid={`task-container-${compliance._id}`}
          >
            <label
              htmlFor={`task-checkbox-${compliance._id}`}
              data-testid={`task-label-${compliance._id}`}
            >
              {compliance.taskName}
            </label>
          </div>
        </td>
        <td data-testid={`reminder-date-${compliance._id}`}>
          {compliance.reminderDate ? formatDate(compliance.reminderDate) : '-'}
        </td>
        <td data-testid={`expiry-date-${compliance._id}`}>
          {compliance.expiryDate ? formatDate(compliance.expiryDate) : '-'}
        </td>
        <td>
          <ComplianceStatus
            value={compliance.status}
            data-testid={`compliance-status-${compliance._id}`}
          />
        </td>
        <td>
          <div className="action-item">
            {!isCRMApp && (
              <>
                <div
                  className="edit-icon"
                  onClick={() => onClickEdit(compliance)}
                  data-testid={`edit-icon-${compliance._id}`}
                >
                  <EditIcon />
                </div>
                <div
                  className="view-icon"
                  onClick={() =>
                    navigate(
                      `/${ERoute.CANDIDATES}/${id}/compliance/${compliance.taskName}`,
                    )
                  }
                  data-testid={`view-icon-${compliance._id}`}
                >
                  <EyeIcon />
                </div>
              </>
            )}
            {/* <div
              className="delete-icon disabled"
              data-testid={`delete-icon-${compliance._id}`}
            >
              <DeleteIcon />
            </div> */}
          </div>
        </td>
      </tr>
      {isOpen && (
        <>
          {subItems.map((el, index) => {
            const isFileExists = !!(
              (el.value as ComplianceFile[])?.length ?? undefined
            );

            const downloadFiles = () => {
              (el.value as ComplianceFile[])?.length
                ? (el.value as ComplianceFile[]).map((el) =>
                    downloadFile(el.file),
                  )
                : undefined;
            };

            return (
              <tr
                data-testid={`compliance-row-sub-item-${compliance._id}-${index}`}
              >
                <td></td>
                <td>
                  <div className={`task-container`}>
                    <label>{el.label}</label>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td className="check-icon">
                  {isFileExists ? <CheckIcon /> : '-'}
                </td>
                <td>
                  {' '}
                  <div className="action-item">
                    {!isCRMApp && isFileExists && (
                      <>
                        <div
                          className="download-icon"
                          onClick={() => downloadFiles()}
                        >
                          <DownloadIcon />
                        </div>
                      </>
                    )}
                    {/* <div
              className="delete-icon disabled"
              data-testid={`delete-icon-${compliance._id}`}
            >
              <DeleteIcon />
            </div> */}
                  </div>
                </td>
              </tr>
            );
          })}
        </>
      )}
    </>
  );
};

export const ComplianceList = ({
  compliancesList,
  getComplianceList,
}: ComplianceListProps) => {
  const { id } = useParams();

  const { openModal, closeModal } = useModal();

  const onClickEdit = (compliance: Compliance) => {
    openModal({
      title: 'Update Date',
      component: (
        <ComplianceDateForm
          compliance={compliance}
          onCancel={() => closeModal()}
          onSuccess={() => {
            getComplianceList?.();
            closeModal();
          }}
          data-testid="compliance-date-form"
        />
      ),
    });
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>Task</th>
            <th>Reminder Date</th>
            <th>Expiry Date</th>
            <th>Status</th>
            <th className="action-th">Action</th>
          </tr>
        </thead>
        <tbody>
          {compliancesList.length > 0 ? (
            <>
              {compliancesList.map((el) => {
                return (
                  <ComplianceRow
                    compliance={el}
                    id={id!}
                    onClickEdit={onClickEdit}
                  />
                );
              })}
            </>
          ) : (
            <tr>
              <td
                colSpan={100}
                className="text-center"
                data-testid="no-item-found"
              >
                No item found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};
