export enum ERoute {
  LOGIN = 'login',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  VERIFY_OTP = 'verify-otp',
  DASHBOARD = 'dashboard',
  CLIENTS = 'clients',
  TRUSTED_CLIENTS = 'trusts',
  CANDIDATES = 'candidates',
  SHARED_CANDIDATES = 'shared-candidates',
  VACANCIES = 'vacancies',
  PAYROLLS = 'payrolls',
  PLACEMENTS = 'placements',
  CALENDAR = 'calendar',
  SETTING = 'settings',
  MESSAGES = 'messages',
  EMAILS = 'emails',
  LEADS = 'leads',
  PROJECTS = 'projects',
  REPORTING = 'reporting',
}
