import styled from 'styled-components';
import { breakpoint } from '../../../constants';

export const ComplianceEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .compliance-status {
    color: #a0aec0;
    font-size: 14px;
    font-weight: 400;
  }

  .description {
    color: #687588;
    font-size: 12px;
    font-weight: 500;
  }

  .perform-check-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .medical-option-container {
    border-radius: 12px;
    padding: 15px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f1f2f4;
  }

  .compliance-completion {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    .progress-container {
      flex: 1;
    }
  }

  .government-check-switch {
    display: flex;
    gap: 10px;

    .form-check-input {
      cursor: pointer;
    }

    .form-check-label {
      font-size: 14px;
    }
  }

  .upload-buttons-container {
    display: flex;
    align-items: flex-end;
    .button-select {
      > Button {
        height: 61px;
        width: 100%;
      }
    }
  }

  .question-toggle-container {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    background-color: #f5f5f5;

    .label {
      flex: 1;
    }

    > div {
      font-size: 14px;
      font-weight: 700;
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    gap: 10;

    .compliance-status {
      font-size: 10px;
    }

    .description {
      font-size: 10px;
    }

    .government-check-switch {
      gap: 5px;

      .form-check-label {
        font-size: 10px;
      }
    }

    .upload-buttons-container {
      display: flex;
      align-items: flex-end;

      .button-select {
        > div {
          height: 40px;
        }
      }
    }
  }
`;
