import React, { useEffect, useState } from 'react';
import {
  Select,
  TitleCard,
  FieldText,
  Button,
  FormContainer,
  Switch,
} from '../ui';
import {
  Days,
  jobLevelWithGrades,
  shiftList,
  specialtiesList,
} from '../../constants';
import { useForm } from 'react-hook-form';
import {
  Client,
  Job,
  User,
  UpdateJobDto,
  EJobStatus,
} from '../../backend/careo-api';
import { AxiosInstance, vacancySchema } from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { InfoIcon } from '../../icons';
import { CoreHours } from '../client/charges-rates.style';

export const UpdateVacancyForm = ({
  onCancel,
  onSuccess,
  vacancy,
  usersList,
  clientsList,
}: {
  onCancel: () => void;
  onSuccess: () => void;
  vacancy: Job;
  usersList: User[];
  clientsList: Client[];
}) => {
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm<UpdateJobDto>({
    resolver: yupResolver(vacancySchema as any),
    defaultValues: {
      coreHours: {
        monday: { from: '08:00', to: '17:30', isWorkingDay: true },
        tuesday: { from: '08:00', to: '17:30', isWorkingDay: true },
        wednesday: { from: '08:00', to: '17:30', isWorkingDay: true },
        thursday: { from: '08:00', to: '17:30', isWorkingDay: true },
        friday: { from: '08:00', to: '17:30', isWorkingDay: true },
        saturday: { from: '08:00', to: '17:30', isWorkingDay: false },
        sunday: { from: '08:00', to: '17:30', isWorkingDay: false },
      },
    },
  });
  const formValues = watch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    AxiosInstance.jobs
      .jobsControllerUpdate(vacancy._id, {
        ...values,
      })
      .then(() => {
        toast.success('Vacancy updated successfully');
        onSuccess();
      })
      .catch(() => {
        toast.error('Something went wrong');
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    reset({
      ...vacancy,
      userId: vacancy.user?._id,
      clientId: vacancy.client?._id,
      vacantFrom: vacancy.vacantFrom?.split?.('T')?.[0] ?? '',
      vacantTo: vacancy.vacantTo?.split?.('T')?.[0] ?? '',
    });
  }, [vacancy]);

  useEffect(() => {
    console.log({ errors });
  }, [errors]);

  return (
    <FormContainer data-testid="form-container">
      <div className="row">
        <Select
          placeholder="Select recruiter"
          required
          label="Recruiter"
          options={usersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          }))}
          register={register('userId')}
          error={errors.userId}
          control={control}
          data-testid="select-recruiter"
        />
        <TitleCard data-testid="title-card-vacancy-details">
          Vacancy Details
        </TitleCard>
        <Select
          placeholder="Select client"
          label="Client"
          options={clientsList.map((el) => ({
            label: el.clientName,
            value: el._id,
          }))}
          register={register('clientId')}
          error={errors.clientId}
          control={control}
          data-testid="select-client"
        />
        <div className="col-md-6" data-testid="col-start-date">
          <FieldText
            placeholder="Enter here ..."
            label="Start Date"
            required
            type="date"
            register={register('vacantFrom')}
            error={errors.vacantFrom}
            data-testid="field-start-date"
          />
        </div>
        <div className="col-md-6" data-testid="col-end-date">
          <FieldText
            placeholder="Enter here ..."
            label="End Date"
            required
            type="date"
            register={register('vacantTo')}
            error={errors.vacantTo}
            data-testid="field-end-date"
          />
        </div>
        <CoreHours
          className="row core-hours-container"
          data-testid="core-hours-container"
        >
          <div
            className="row core-hours-header"
            data-testid="core-hours-header"
            style={{}}
          >
            <div className="col-md-4">Working Day</div>
            <div className="col-md-4">Core From</div>
            <div className="col-md-4">Core Till</div>
          </div>

          {Days.map((day) => {
            return (
              <div className="row days" data-testid="day-core-hours">
                <div className="col-md-4 box">
                  <Switch
                    value={formValues.coreHours?.[day]?.isWorkingDay!}
                    onClick={() =>
                      setValue(
                        `coreHours.${day}.isWorkingDay`,
                        !formValues.coreHours?.[day]?.isWorkingDay,
                      )
                    }
                  />
                  {day}
                </div>
                <div className="col-md-4 box">
                  {formValues.coreHours?.[day]?.isWorkingDay ? (
                    <FieldText
                      placeholder="Enter here ..."
                      type="time"
                      required
                      register={register(`coreHours.${day}.from`, {
                        required: true,
                      })}
                      error={errors.coreHours?.[day]?.from}
                      data-testid="charge-rate-field"
                    />
                  ) : (
                    <div className="non-working-day">
                      <InfoIcon />
                      Non-working day
                    </div>
                  )}
                </div>
                <div className="col-md-4 box">
                  {formValues.coreHours?.[day]?.isWorkingDay ? (
                    <FieldText
                      placeholder="Enter here ..."
                      type="time"
                      required
                      register={register(`coreHours.${day}.to`, {
                        required: true,
                      })}
                      error={errors.coreHours?.[day]?.to}
                      data-testid="charge-rate-field"
                    />
                  ) : (
                    <div className="non-working-day">
                      <InfoIcon />
                      Non-working day
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </CoreHours>
        <Select
          options={shiftList}
          placeholder="Select Shift"
          label="Shift"
          required
          register={register('shift')}
          error={errors.shift}
          control={control}
          data-testid="select-shift"
        />
        <Select
          options={jobLevelWithGrades.map((el) => ({
            label: el.level,
            value: el.level,
          }))}
          placeholder="Select Job Title"
          label="level"
          required
          register={register('level')}
          error={errors.level}
          control={control}
          data-testid="select-job-title"
        />
        <div className="col-md-6">
          <Select
            options={
              jobLevelWithGrades
                .find((el) => el.level === formValues.level)
                ?.grades?.map((el) => ({ label: el, value: el })) ?? []
            }
            placeholder="Select Grade"
            label="grade"
            required
            register={register('grade')}
            error={errors.grade}
            disabled={!formValues.level}
            control={control}
            data-testid="select-grade"
          />
        </div>
        <div className="col-md-6">
          <Select
            options={specialtiesList.map((el) => ({ label: el, value: el }))}
            placeholder="Select Specialty"
            label="Specialty"
            required
            register={register('specialty', { required: true })}
            error={errors.specialty}
            control={control}
            data-testid="select-specialty"
          />
        </div>
        <FieldText
          placeholder="Enter here ..."
          label="Job Description"
          required
          register={register('jobDescription', { required: true })}
          error={errors.jobDescription}
          data-testid="field-job-description"
        />
        <Select
          options={[
            { label: 'Hired', value: EJobStatus.Hired },
            { label: 'Pending', value: EJobStatus.Pending },
            { label: 'Active', value: EJobStatus.Active },
            { label: 'Closed', value: EJobStatus.Closed },
          ]}
          placeholder="Select Vacancy Status"
          label="Status"
          required
          register={register('status', { required: true })}
          error={errors.status}
          control={control}
          data-testid="select-vacancy-status"
        />
        <TitleCard data-testid="placement-charges-title">Charges</TitleCard>
        <div className="col-md-6" data-testid="col-charge">
          <FieldText
            placeholder="Enter here ..."
            label="Charge"
            required
            register={register('charge', { required: true })}
            error={errors.charge}
            data-testid="field-charge"
            type="currency"
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="VAT"
            required
            register={register('vat', { required: true })}
            type="currency"
            min={0}
            error={errors.vat}
            data-testid="vat"
          />
        </div>
        <TitleCard data-testid="placement-charges-title">Rates</TitleCard>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Core Rate (per hour)"
            required
            register={register('corePrice', { required: true })}
            type="currency"
            min={0}
            error={errors.corePrice}
            data-testid="on-call-rate"
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Unsocial Rate (per hour)"
            required
            register={register('unSocialPrice', { required: true })}
            type="currency"
            min={0}
            error={errors.unSocialPrice}
            data-testid="unsocial-rate"
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="On Call Active Rate (per hour)"
            required
            register={register('onCallActivePrice', { required: true })}
            type="currency"
            min={0}
            error={errors.onCallActivePrice}
            data-testid="on-call-active-rate"
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="On Call Passive Rate (per hour)"
            required
            register={register('onCallPassivePrice', { required: true })}
            type="currency"
            min={0}
            error={errors.onCallPassivePrice}
            data-testid="on-call-passive-rate"
          />
        </div>
      </div>
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={onCancel}
          data-testid="button-cancel"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="button-update"
        >
          Update
        </Button>
      </div>
    </FormContainer>
  );
};
