import React, { useEffect, useState } from 'react';
import { TitleCard, RadioGroup, FieldText } from '../../ui';
import {
  Compliance,
  ComplianceFile,
  ComplianceMetaData,
  HostedFile,
} from '../../../backend/careo-api';
import ComplianceUpload from '../compliance-edit/compliance-upload';

type IdentityInformationProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: ComplianceMetaData,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey =
  | 'passportFiles'
  | 'drivingLicenseFiles'
  | 'proofOfAddressFiles';

type TAllFields =
  | TFilesKey
  | 'passportType'
  | 'passportExpiryDate'
  | 'drivingLicenseExpiryDate'
  | 'proofOfAddressExpiryDate';

export const IdentityInformation = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: IdentityInformationProps) => {
  const [isApproved, setIsApproved] = useState<boolean>();
  const [passportType, setPassportType] = useState<string>();
  const [passportExpiryDate, setPassportExpiryDate] = useState<string>();
  const [drivingLicenseExpiryDate, setDrivingLicenseExpiryDate] =
    useState<string>();
  const [proofOfAddressExpiryDate, setProofOfAddressExpiryDate] =
    useState<string>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; data: ComplianceFile[] }[]
  >([
    { key: 'passportFiles', data: [] },
    { key: 'drivingLicenseFiles', data: [] },
    { key: 'proofOfAddressFiles', data: [] },
  ]);

  const onUpdateCompliance = (
    key: TAllFields,
    value: any,
    successMessage?: string,
  ) => {
    onClickSubmit(compliance._id, { [key]: value }, false, successMessage);
  };

  useEffect(() => {
    if (compliance) {
      const passportFiles = compliance?.metadata?.passportFiles ?? [];
      const drivingLicenseFiles =
        compliance?.metadata?.drivingLicenseFiles ?? [];
      const proofOfAddressFiles =
        compliance?.metadata?.proofOfAddressFiles ?? [];

      setFilesList([
        { key: 'passportFiles', data: passportFiles },
        { key: 'drivingLicenseFiles', data: drivingLicenseFiles },
        { key: 'proofOfAddressFiles', data: proofOfAddressFiles },
      ]);

      setPassportType(compliance.metadata?.passportType);
      setPassportExpiryDate(
        compliance.metadata?.passportExpiryDate?.split('T')[0],
      );
      setDrivingLicenseExpiryDate(
        compliance.metadata?.drivingLicenseExpiryDate?.split('T')[0],
      );
      setProofOfAddressExpiryDate(
        compliance.metadata?.proofOfAddressExpiryDate?.split('T')[0],
      );
      setIsApproved(compliance.isApproved);
    }
  }, [compliance]);

  return (
    <>
      <>
        <TitleCard data-testid="title-card-passportFiles">Passport</TitleCard>
        <RadioGroup
          name="passportType"
          options={[
            {
              label: 'British Passport (incl N.Ireland)',
              value: 'British',
            },
            { label: 'Other', value: 'Other' },
          ]}
          value={passportType}
          onChange={(value) => {
            onUpdateCompliance('passportType', value);
          }}
          data-testid="passportType-radio-group"
        />

        <ComplianceUpload
          fileKey="passportFiles"
          isMulti
          label="Upload Passport"
          description="Does it contain all required fields."
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />

        <div className="row">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Expiry Date"
              required
              type="date"
              value={passportExpiryDate}
              onChange={(e) => {
                setPassportExpiryDate(e.target.value);
              }}
              onBlur={(e) => {
                onUpdateCompliance('passportExpiryDate', e.target.value);
              }}
            />
          </div>
        </div>
      </>

      <>
        <TitleCard data-testid="title-card-drivingLicenseFiles">
          Driving License
        </TitleCard>

        <ComplianceUpload
          fileKey="drivingLicenseFiles"
          isMulti
          label="Upload Driving License"
          description="Does it contain all required fields."
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
        <div className="row">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Expiry Date"
              required
              type="date"
              value={drivingLicenseExpiryDate}
              onChange={(e) => {
                setPassportExpiryDate(e.target.value);
              }}
              onBlur={(e) => {
                onUpdateCompliance('drivingLicenseExpiryDate', e.target.value);
              }}
            />
          </div>
        </div>
      </>

      <>
        <TitleCard data-testid="title-card-proofOfAddressFiles">
          Proof of Address
        </TitleCard>

        <ComplianceUpload
          fileKey="proofOfAddressFiles"
          isMulti
          label="Upload Files"
          description="Does it contain all required fields."
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
        <div className="row">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Expiry Date"
              required
              type="date"
              value={proofOfAddressExpiryDate}
              onChange={(e) => {
                setProofOfAddressExpiryDate(e.target.value);
              }}
              onBlur={(e) => {
                onUpdateCompliance('proofOfAddressExpiryDate', e.target.value);
              }}
            />
          </div>
        </div>
      </>

      {/* Approved Section */}
      <>
        <TitleCard data-testid="title-card-approved">Approved</TitleCard>
        <RadioGroup
          options={[
            {
              label: 'Pass',
              value: 'true',
            },
            {
              label: 'Fail',
              value: 'false',
            },
          ]}
          name="approved"
          value={isApproved?.toString()}
          onChange={(value: any) => onUpdateValidation(compliance._id, value)}
          data-testid="approved-radio-group"
        />
        {/* <div
          className="info-card compliance-status buttons-container validated-by-ai"
          data-testid="validate-by-ai-button-container"
        >
          <Button
            type="primary"
            onClick={() => onClickSubmit(compliance._id, {}, true)}
            data-testid="validate-by-ai-button"
          >
            Validate By AI
          </Button>
        </div> */}
      </>
    </>
  );
};
