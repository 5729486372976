import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  FieldText,
  FormContainer,
  RadioGroup,
  Switch,
  TitleCard,
} from '../ui';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ChargesRates,
  CreateRateClientDto,
  EChargeRateType,
  EJobLevel,
  ENhsPayGrade,
  ESpecialty,
} from '../../backend/careo-api';
import {
  AxiosInstance,
  handleFormsServerErrors,
  rateSchema,
} from '../../utils';
import { BandsPricing, CoreHours } from './charges-rates.style';
import { InfoIcon } from '../../icons';
import { Accordion } from '../ui/accordion';
import { Days } from '../../constants';

type NewRateFormProps = {
  getClientDetails: any;
  onClose: () => void;
  specialty?: ESpecialty;
  selectedRates?: ChargesRates;
};

const gradesPerChargeRateType = [
  {
    type: EChargeRateType.NursesHealthCareAssistants,
    levels: [EJobLevel.Nurse],
    grades: [
      ENhsPayGrade.Band5,
      ENhsPayGrade.Band6,
      ENhsPayGrade.Band7,
      ENhsPayGrade.Band8A,
      ENhsPayGrade.Band8B,
      ENhsPayGrade.Band8C,
      ENhsPayGrade.Band8D,
      ENhsPayGrade.Band9,
    ],
  },

  {
    type: EChargeRateType.Doctors,
    levels: [EJobLevel.JuniorDoctor, EJobLevel.MiddleGrade],
    grades: [
      ENhsPayGrade.FY1,
      ENhsPayGrade.FY2,
      ENhsPayGrade.ST1,
      ENhsPayGrade.ST2,
      ENhsPayGrade.SeniorHouseOfficer,
      ENhsPayGrade.ST3,
      ENhsPayGrade.ST4,
      ENhsPayGrade.ST5,
      ENhsPayGrade.ST6,
      ENhsPayGrade.ST7,
      ENhsPayGrade.ST8,
      ENhsPayGrade.AssociateSpecialist,
      ENhsPayGrade.StaffGrade,
      ENhsPayGrade.SpecialtyDoctor,
    ],
  },
];

export const RateForm = ({
  onClose,
  specialty,
  selectedRates,
}: NewRateFormProps) => {
  const { id } = useParams();

  const {
    register,
    getValues,
    formState: { errors },
    watch,
    setValue,
    setError,
    setFocus,
    handleSubmit,
    reset,
  } = useForm<CreateRateClientDto>({
    resolver: yupResolver(rateSchema as any),
    defaultValues: {
      type: EChargeRateType.Doctors,
      coreHours: {
        monday: { from: '08:00', to: '17:30', isWorkingDay: true },
        tuesday: { from: '08:00', to: '17:30', isWorkingDay: true },
        wednesday: { from: '08:00', to: '17:30', isWorkingDay: true },
        thursday: { from: '08:00', to: '17:30', isWorkingDay: true },
        friday: { from: '08:00', to: '17:30', isWorkingDay: true },
        saturday: { from: '08:00', to: '17:30', isWorkingDay: false },
        sunday: { from: '08:00', to: '17:30', isWorkingDay: false },
      },
    },
  });

  const formValues = watch();

  const onClickClose = () => {
    onClose();
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = async () => {
    setIsSubmitting(true);
    const values = getValues();

    try {
      if (!id) {
        return;
      }
      if (!selectedRates)
        await AxiosInstance.clients.clientsControllerAddRate(id, {
          ...values,
          specialty,
        });
      else
        await AxiosInstance.clients.clientsControllerUpdateRate(
          selectedRates._id,
          {
            ...values,
            specialty,
          },
        );
      onClickClose();
    } catch (error: any) {
      handleFormsServerErrors(error, setError, setFocus);
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    console.log({ errors });
  }, [errors]);

  useEffect(() => {
    console.log({ specialty });
    if (selectedRates) {
      reset(selectedRates);
    }
  }, []);

  const grades = useMemo(() => {
    return gradesPerChargeRateType.find((i) => i.type === formValues.type)
      ?.grades;
  }, [formValues.type]);

  useEffect(() => {
    if (!selectedRates)
      setValue(
        'pricing',
        grades?.map((grade) => {
          return {
            charge: 0,
            corePrice: 0,
            grade,
            onCallActivePrice: 0,
            onCallPassivePrice: 0,
            unSocialPrice: 0,
          };
        }) || [],
      );
  }, [grades, selectedRates]);

  return (
    <FormContainer data-testid="form-container">
      <TitleCard data-testid="contact-title">Type</TitleCard>
      <RadioGroup
        options={[
          {
            label: 'Doctors',
            value: EChargeRateType.Doctors,
          },
          {
            label: 'Nurses & health Care Assistants',
            value: EChargeRateType.NursesHealthCareAssistants,
          },
        ]}
        name={'type'}
        register={register('type', { required: true })}
        error={errors?.type}
        data-testid="schedule-frequency-group"
      />

      <TitleCard data-testid="contact-title">Core Hours</TitleCard>

      <CoreHours
        className="row core-hours-container"
        data-testid="core-hours-container"
      >
        <div
          className="row core-hours-header"
          data-testid="core-hours-header"
          style={{}}
        >
          <div className="col-md-3">Working Day</div>
          <div className="col-md-4">Core From</div>
          <div className="col-md-4">Core Till</div>
        </div>

        {Days.map((day) => {
          return (
            <div className="row days" data-testid="day-core-hours">
              <div className="col-md-3 box">
                <Switch
                  value={formValues.coreHours?.[day]?.isWorkingDay}
                  onClick={() =>
                    setValue(
                      `coreHours.${day}.isWorkingDay`,
                      !formValues.coreHours?.[day]?.isWorkingDay,
                    )
                  }
                />
                {day}
              </div>
              <div className="col-md-4 box">
                {formValues.coreHours?.[day]?.isWorkingDay ? (
                  <FieldText
                    placeholder="Enter here ..."
                    type="time"
                    required
                    register={register(`coreHours.${day}.from`, {
                      required: true,
                    })}
                    error={errors.coreHours?.[day]?.from}
                    data-testid="charge-rate-field"
                  />
                ) : (
                  <div className="non-working-day">
                    <InfoIcon />
                    Non-working day
                  </div>
                )}
              </div>
              <div className="col-md-4 box">
                {formValues.coreHours?.[day]?.isWorkingDay ? (
                  <FieldText
                    placeholder="Enter here ..."
                    type="time"
                    required
                    register={register(`coreHours.${day}.to`, {
                      required: true,
                    })}
                    error={errors.coreHours?.[day]?.to}
                    data-testid="charge-rate-field"
                  />
                ) : (
                  <div className="non-working-day">
                    <InfoIcon />
                    Non-working day
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </CoreHours>

      <TitleCard data-testid="contact-title">Band Pricing</TitleCard>

      <BandsPricing>
        {grades?.map((grade, index) => {
          return (
            <Accordion title={grade} className="primary-item" key={grade}>
              <div className="row item">
                <div className="row ">
                  <div className="col-md-6 ">
                    <FieldText
                      placeholder="Enter here ..."
                      label="Core"
                      type="number"
                      required
                      register={register(`pricing.${index}.corePrice`, {
                        required: true,
                      })}
                      error={errors.pricing?.[index]?.corePrice}
                    />
                  </div>

                  <div className="col-md-6 ">
                    <FieldText
                      placeholder="Enter here ..."
                      label="Unsocial"
                      type="number"
                      required
                      register={register(`pricing.${index}.unSocialPrice`, {
                        required: true,
                      })}
                      error={errors.pricing?.[index]?.unSocialPrice}
                    />
                  </div>

                  <div className="col-md-6 ">
                    <FieldText
                      placeholder="Enter here ..."
                      label="On-Call Active"
                      type="number"
                      required
                      register={register(`pricing.${index}.onCallActivePrice`, {
                        required: true,
                      })}
                      error={errors.pricing?.[index]?.onCallActivePrice}
                    />
                  </div>

                  <div className="col-md-6 ">
                    <FieldText
                      placeholder="Enter here ..."
                      label="On-Call Passive"
                      type="number"
                      required
                      register={register(
                        `pricing.${index}.onCallPassivePrice`,
                        {
                          required: true,
                        },
                      )}
                      error={errors.pricing?.[index]?.onCallPassivePrice}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <FieldText
                      placeholder="Enter here ..."
                      label="Charge"
                      type="number"
                      required
                      register={register(`pricing.${index}.charge`, {
                        required: true,
                      })}
                      error={errors.pricing?.[index]?.charge}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
          );
        })}
      </BandsPricing>

      {/* <FieldText
        placeholder="Enter here ..."
        label="Charge"
        type="number"
        required
        register={register('charge', { required: true })}
        error={errors.charge}
        data-testid="charge-rate-field"
      />
      <FieldText
        placeholder="Enter here ..."
        label="Rate"
        type="number"
        required
        register={register('rate', { required: true })}
        error={errors.rate}
        data-testid="rate-rate-field"
      /> */}
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={onClickClose}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="create-button"
        >
          Submit
        </Button>
      </div>
    </FormContainer>
  );
};
