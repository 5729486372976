import React, { useEffect, useMemo, useState } from 'react';
import {
  TimesheetActionContainer,
  TimesheetContainer,
} from './timesheet.style';
import { AppIcon } from '../../icons';
import { Button, FieldText, Textarea } from '../../components';
import { AxiosInstance, formatDate, getTotalWorkingHours } from '../../utils';
import { toast } from 'react-toastify';
import { AddTimingsDto, Timesheet } from '../../backend/careo-api';
import { useLocation, useParams } from 'react-router-dom';
import { durations } from '../../constants';

type TimesheetClientPageProps = {
  isDownload?: boolean;
};

const TimesheetClientPage = ({ isDownload }: TimesheetClientPageProps) => {
  const { token } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const download = queryParams.get('download');

  const [timings, setTimings] = useState<AddTimingsDto[]>([]);
  const [timesheet, setTimesheet] = useState<Timesheet | null>(null);
  const [signature, setSignature] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const totalOfWorkingHours: string = useMemo(() => {
    return getTotalWorkingHours(timings);
  }, [timings]);

  const getTimesheetDetails = async () => {
    try {
      let result;
      if (isDownload) {
        result = (
          await AxiosInstance.timesheets.timesheetsControllerFindOne(token!)
        ).data;
      } else {
        result = (
          await AxiosInstance.timesheetMember.timesheetMemberControllerFindOneByToken(
            token!,
          )
        ).data;
      }

      if (result) {
        setTimesheet(result);
        setTimings((result.timings ?? []) as AddTimingsDto[]);
        if (result.status === 'Approved') {
          setSignature(result.signature ?? '');
          setIsAgreed(true);
        }
      }
      setIsLoading(false);
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const onClickApprove = async () => {
    try {
      await AxiosInstance.timesheetMember.timesheetMemberControllerApproveByClientToken(
        token!,
        {
          signature,
        },
      );
      toast.success('Timesheet approved successfully');
      getTimesheetDetails();
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const onClickReject = async () => {
    try {
      await AxiosInstance.timesheetMember.timesheetMemberControllerRejectByClientToken(
        token!,
      );
      getTimesheetDetails();
      toast.success('Timesheet rejected successfully');
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    getTimesheetDetails();
  }, []);

  if (isLoading) {
    return <></>;
  }

  if (!timesheet || timesheet.status === 'Generated') {
    return <>Timesheet not found</>;
  }

  return (
    <>
      <TimesheetContainer
        className="container"
        id="container"
        data-testid="timesheet-container"
      >
        <div className="logo-container" data-testid="logo-container">
          <AppIcon />
        </div>
        <div className="title" data-testid="timesheet-status">
          {timesheet.status === 'Pending'
            ? 'Please approve the submitted timesheet below'
            : timesheet.status === 'Approved'
              ? 'Timesheet is already approved'
              : 'Timesheet is already rejected'}
        </div>
        <div className="title" data-testid="timesheet-title">
          Timesheet
        </div>
        <FieldText
          label="Placement"
          value={
            timesheet?.placement.job.client?.clientName +
            ' - ' +
            timesheet?.placement.job.specialty
          }
          disabled
          data-testid="placement-field"
        />
        <div className="subtitle" data-testid="personal-details-subtitle">
          Personal Details
        </div>
        <div
          className="personal-info-container"
          data-testid="personal-info-container"
        >
          <div className="personal-name" data-testid="personal-name">
            {timesheet?.placement?.candidate?.title}{' '}
            {timesheet?.placement?.candidate?.firstName}{' '}
            {timesheet?.placement?.candidate?.lastName}
          </div>
          <div className="row">
            <div className="col-md-6 row" data-testid="specialty-row">
              <div
                className="info-item-title col-5"
                data-testid="specialty-title"
              >
                Specialty
              </div>
              <div
                className="info-item-data col-7"
                data-testid="specialty-data"
              >
                {timesheet?.placement?.candidate?.designation || '-'}
              </div>
            </div>
            <div className="col-md-6 row" data-testid="department-row">
              <div
                className="info-item-title col-5"
                data-testid="department-title"
              >
                Department
              </div>
              <div
                className="info-item-data col-7"
                data-testid="department-data"
              >
                {timesheet?.placement?.candidate?.departments?.join(',') || '-'}
              </div>
            </div>
            <div className="col-md-6 row" data-testid="week-commencing-row">
              <div
                className="info-item-title col-5"
                data-testid="week-commencing-title"
              >
                Week Commencing
              </div>
              <div
                className="info-item-data col-7"
                data-testid="week-commencing-data"
              >
                {formatDate(timesheet?.placement?.availableFrom ?? '')}
              </div>
            </div>
            <div className="col-md-6 row" data-testid="team-row">
              <div className="info-item-title col-5" data-testid="team-title">
                Team
              </div>
              <div className="info-item-data col-7" data-testid="team-data">
                {timesheet?.placement?.job.specialty}
              </div>
            </div>
            <div className="col-md-6 row" data-testid="email-address-row">
              <div className="info-item-title col-5" data-testid="email-title">
                Email Address
              </div>
              <div className="info-item-data col-7" data-testid="email-data">
                {timesheet?.placement?.candidate.email}
              </div>
            </div>
            <div className="col-md-6 row" data-testid="grade-row">
              <div className="info-item-title col-5" data-testid="grade-title">
                Grade
              </div>
              <div className="info-item-data col-7" data-testid="grade-data">
                {timesheet?.placement?.job?.grade}
              </div>
            </div>
          </div>
        </div>
        <div className="subtitle" data-testid="timesheet-subtitle">
          Timesheet below
        </div>
        <div className="text" data-testid="breaks-note">
          Breaks in the same detail as the Assignment confirmation
        </div>
        {timings?.map((el, index) => {
          return (
            <div
              className="personal-info-container"
              data-testid={`timing-${index}`}
              key={index}
            >
              <div className="personal-name" data-testid={`day-name-${index}`}>
                {new Date(el.date).toLocaleDateString('en-US', {
                  weekday: 'long',
                })}{' '}
                - {formatDate(el.date)}
              </div>
              <div className="row">
                <div
                  className="col-md-6 row"
                  data-testid={`start-finish-row-${index}`}
                >
                  <div
                    className="info-item-title col-6"
                    data-testid={`start-time-title-${index}`}
                  >
                    {' '}
                    Start Time
                  </div>
                  <div
                    className="info-item-data col-6"
                    data-testid={`start-time-data-${index}`}
                  >
                    {el.startTime}{' '}
                  </div>
                  <div
                    className="info-item-title col-6"
                    data-testid={`finish-time-title-${index}`}
                  >
                    {' '}
                    Finish Time
                  </div>
                  <div
                    className="info-item-data col-6"
                    data-testid={`finish-time-data-${index}`}
                  >
                    {el.endTime}{' '}
                  </div>
                </div>
                <div
                  className="col-md-6 row"
                  data-testid={`on-call-breaks-row-${index}`}
                >
                  <div className="row">
                    <div
                      className="info-item-title col-6"
                      data-testid={`on-call-title-${index}`}
                    >
                      On Call Active Hours
                    </div>
                    <div
                      className="info-item-data col-6"
                      data-testid={`on-call-data-${index}`}
                    >
                      {(el.callActiveHours ?? 0) <= 0
                        ? '-'
                        : `${el.callActiveHours} Hours`}
                    </div>

                    <div
                      className="info-item-title col-6"
                      data-testid={`on-call-title-${index}`}
                    >
                      On Call Passive Hours
                    </div>
                    <div
                      className="info-item-data col-6"
                      data-testid={`on-call-data-${index}`}
                    >
                      {(el.callPassiveHours ?? 0) <= 0
                        ? '-'
                        : `${el.callPassiveHours} Hours`}
                    </div>
                    <div
                      className="info-item-title col-6"
                      data-testid={`breaks-title-${index}`}
                    >
                      {' '}
                      Breaks
                    </div>
                    <div
                      className="info-item-data col-6"
                      data-testid={`breaks-data-${index}`}
                    >
                      {durations.find(
                        (duration) => duration.value === el.breakTime,
                      )?.label ?? '-'}{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="subtitle" data-testid="signature-subtitle">
          Verified by authorized signature holder
        </div>
        <div className="row" data-testid="signature-row">
          <div className="col-md-6" data-testid="signature-name">
            <div className="row">
              <div
                className="info-item-title col-5"
                data-testid="signature-name-title"
              >
                Name
              </div>
              <div
                className="info-item-data col-7"
                data-testid="signature-name-data"
              >
                {timesheet?.placement?.job?.client?.firstName}{' '}
                {timesheet?.placement?.job?.client?.lastName}
              </div>
            </div>
            <div className="row">
              <div
                className="info-item-title col-5"
                data-testid="signature-position-title"
              >
                Position
              </div>
              <div
                className="info-item-data col-7"
                data-testid="signature-position-data"
              >
                {timesheet?.placement?.job?.client?.jobTitle}
              </div>
            </div>
          </div>
          <div
            className="col-md-6 signature-container"
            data-testid="signature-container"
          >
            <div className="info-item-title" data-testid="signature-title">
              Signature
            </div>
            <Textarea
              className="textarea"
              onChange={(e) => setSignature(e.target.value)}
              disabled={timesheet.status !== 'Pending'}
              value={signature}
              data-testid="signature-textarea"
            />
          </div>
        </div>
        <div className="agree-container" data-testid="agree-container">
          <input
            type="checkbox"
            id="agree-checkbox"
            onChange={(e) => setIsAgreed(e.target.checked)}
            disabled={timesheet.status !== 'Pending'}
            checked={isAgreed}
            data-testid="agree-checkbox"
          />
          <label
            className="checkbox-text"
            htmlFor="agree-checkbox"
            data-testid="agree-label"
          >
            I agree all the information submitted is correct
          </label>
        </div>
        <div
          className="declaration-container"
          data-testid="declaration-container"
        >
          <div
            className="text title-declaration"
            data-testid="declaration-title"
          >
            Declaration:
          </div>
          <div className="text" data-testid="declaration-text">
            I declare that the information above is current and complete, and
            all hours are approved by an authorized supervisor and signature
            holder. Providing false information can result in the event of
            investigation so please alert us immediately if you notice an error
            in how many hours are claimed. Signed timesheets will follow the
            terms and conditions linked to this locum assignment. For access to
            the agreement please contact your account manager.
          </div>
          <br />
          <div
            className="text contact-declaration"
            data-testid="contact-declaration"
          >
            For information relating to payment or any other matter relating to
            this assignment, please contact us.
          </div>
        </div>
      </TimesheetContainer>
      {download !== 'pdf' && (
        <TimesheetActionContainer
          className="container"
          data-testid="timesheet-action-container"
        >
          <div className="left-container" data-testid="total-working-time">
            Total Working Time :{' '}
            <label data-testid="working-time-label">
              {totalOfWorkingHours}
            </label>
          </div>
          <div
            className="right-container"
            data-testid="action-buttons-container"
          >
            <Button
              type="primary"
              onClick={onClickReject}
              disabled={!isAgreed || timesheet.status !== 'Pending'}
              data-testid="reject-button"
            >
              Reject
            </Button>
            <Button
              type="primary"
              disabled={!isAgreed || timesheet.status !== 'Pending'}
              onClick={onClickApprove}
              data-testid="approve-button"
            >
              Approve
            </Button>
          </div>
        </TimesheetActionContainer>
      )}
    </>
  );
};

export default TimesheetClientPage;
