import React, { useState } from 'react';
import styled from 'styled-components';
import { DropdownSelectText } from '../../ui';
import { DonutChartPercentage } from '../../charts/donut-chart';
import { formatCurrency } from '../../../utils';

export const RevenueChartContainer = styled.div`
  background-color: white;
  min-width: 300px;
  padding: 24px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;

  .header {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: 500;
      font-size: 20px;
    }
    .selection {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
  }

  .labels-container {
    display: flex;
    flex-direction: column;
    gap: 14px;
    .label {
      display: flex;
      justify-content: space-between;
      > div:nth-child(1) {
        color: #687588;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7px;
        > div:nth-child(1) {
          width: 14px;
          height: 14px;
          border-radius: 7px;
        }
      }

      > div:nth-child(2) {
        font-weight: 600;
      }
    }

    .show-more {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: gray;
      cursor: pointer;

      &:hover {
        color: black;
        .chevron {
          stroke: black;
        }
      }

      .chevron {
        stroke: gray;
      }
    }
  }
`;

type RevenueChartProps = {
  isLoading: boolean;
  chartData: {
    backgroundColor: string;
    borderColor: string;
    label: string;
    value: number;
    percentage: number;
  }[];
};

export const RevenueChart = ({ isLoading, chartData }: RevenueChartProps) => {
  const [duration, setDuration] = useState('');

  return (
    <RevenueChartContainer>
      <div className="header">
        <span className="title" data-testid="revenue-title">
          {chartData[0].label}
        </span>
        <div className="selection" data-testid="dropdown-select-container">
          <DropdownSelectText
            items={[
              {
                label: 'All Time',
                value: '',
              },
            ]}
            selectedItem={duration}
            setSelectedItem={setDuration}
            data-testid="duration-dropdown"
          />
        </div>
      </div>
      <DonutChartPercentage
        config={{
          labels: chartData.map((el) => el.label),
          backgroundColors: chartData.map((el) => el.backgroundColor),
          borderColors: chartData.map((el) => el.borderColor),
        }}
        data={chartData.map((el) => el?.percentage)}
        isLoading={isLoading}
        data-testid="donut-chart"
      />
      <div className="labels-container" data-testid="labels-container">
        {chartData.map((el) => (
          <div
            className="label"
            key={el.label}
            data-testid={`chart-label-${el.label}`}
          >
            <div>
              <div
                style={{
                  backgroundColor: el.backgroundColor,
                }}
                data-testid={`label-color-${el.label}`}
              ></div>
              <div>{el.label}</div>
            </div>
            <div>{formatCurrency(el.value)}</div>
          </div>
        ))}
      </div>
    </RevenueChartContainer>
  );
};
