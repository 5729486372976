import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  CardContainer,
  HeaderPageContainer,
  MultipleSelect,
  VacancyForm,
  Pagination,
  SearchInput,
  SubTitlePage,
  Table,
  TitlePage,
  UploadButton,
  UpdateVacancyForm,
} from '../../components';
import {
  CalendarIcon,
  DeleteIcon,
  EditIcon,
  EyeIcon,
  PlusIcon,
  SortIcon,
} from '../../icons';
import {
  TUIType,
  getGradesOfSelectedLevel,
  jobLevelWithGrades,
  regionsWithCounties,
} from '../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  TFilterVacancy,
  filterVacancies,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortVacancies,
  uploadVacanciesCsvRequest,
} from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Client, EJobStatus, Job, User } from '../../backend/careo-api';
import { toast } from 'react-toastify';
import { useModal } from '../../contexts/side-modal.context';
import { useConfirm } from '../../contexts/confirm-modal.context';

const itemsPerPage = 8;

const vacancyStatus: { [key in EJobStatus]: TUIType } = {
  [EJobStatus.Hired]: 'success',
  [EJobStatus.Pending]: 'warning',
  [EJobStatus.Active]: 'primary',
  [EJobStatus.Closed]: 'danger',
};

export const VacanciesListPage = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { openConfirm, closeConfirm } = useConfirm();

  const [vacancies, setVacancies] = useState<Job[]>([]);
  const [vacanciesList, setVacanciesList] = useState<Job[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [clientsList, setClientsList] = useState<Client[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const [filter, setFilter] = useState<TFilterVacancy>({
    search: '',
    regions: [],
    grades: [],
    levels: [],
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });
  const [isLoading, setIsLoading] = useState(true);

  const onClickCreate = () => {
    openModal({
      title: 'New Vacancy',
      component: (
        <VacancyForm
          onCancel={() => closeModal()}
          onSuccess={() => {
            getVacancies();
            closeModal();
            toast.success('Vacancy created successfully');
          }}
          clientsList={clientsList}
          usersList={usersList}
          data-testid="new-vacancy-form"
        />
      ),
    });
  };

  const onClickEdit = (vacancy: Job) => {
    openModal({
      title: 'Update Vacancy',
      component: (
        <UpdateVacancyForm
          clientsList={clientsList}
          usersList={usersList}
          vacancy={vacancy}
          onCancel={() => closeModal()}
          onSuccess={() => {
            getVacancies();
            closeModal();
            toast.success('Vacancy Updated successfully');
          }}
          data-testid="update-vacancy-form"
        />
      ),
    });
  };

  const onDeleteConfirm = (vacancy: Job) => {
    openConfirm({
      title: 'Delete Vacancy',
      component: (
        <>
          Do you want to delete <b>this vacancy</b>
        </>
      ),
      onConfirm: () => deleteVacancy(vacancy),
    });
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.VACANCIES}/${id}`);
  };

  const onClickAvailability = (id: string) => {
    navigate(`/${ERoute.VACANCIES}/${id}/applications`);
  };

  const uploadVacanciesCsv = async (file: File) => {
    await uploadVacanciesCsvRequest(file)
      .then(() => {
        getVacancies();
        toast.success('Vacancies uploaded successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getVacancies = async () => {
    setIsLoading(true);
    await AxiosInstance.jobs
      .jobsControllerFindAll()
      .then((response) => {
        setVacancies(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  const deleteVacancy = async (vacancy: Job) => {
    await AxiosInstance.jobs
      .jobsControllerDeleteJob(vacancy?._id!)
      .then(() => {
        toast.success('Vacancy Removed successfully');
        getVacancies();
        closeConfirm();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerFindAll()
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getClients = async () => {
    AxiosInstance.clients
      .clientsControllerFindAll()
      .then((response) => {
        setClientsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(vacanciesList, currentPage, itemsPerPage);
  }, [vacanciesList, currentPage]);

  useEffect(() => {
    const filteredData = filterVacancies(vacancies, filter);
    const sortedData = sortVacancies(filteredData, sort);
    setVacanciesList(sortedData);
    setTotalPages(getNumberOfPages(sortedData.length, itemsPerPage));
  }, [vacancies, filter, sort]);

  useEffect(() => {
    getVacancies();
    getClients();
    getUsers();
    // onClickCreate();
  }, []);

  return (
    <>
      <CardContainer data-testid="card-container">
        <HeaderPageContainer data-testid="header-page-container">
          <div className="left-container" data-testid="left-container">
            <TitlePage data-testid="title-page">Vacancies</TitlePage>
            <SubTitlePage data-testid="sub-title-page">
              Manage your Vacancy
            </SubTitlePage>
          </div>
          <div className="right-container" data-testid="right-container">
            <UploadButton
              accept=".csv"
              onUpload={uploadVacanciesCsv}
              data-testid="upload-button"
            >
              Upload
            </UploadButton>
            <Button
              type="primary"
              onClick={() => onClickCreate()}
              data-testid="add-new-button"
            >
              <PlusIcon data-testid="plus-icon" /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container" data-testid="filter-container">
          <SearchInput
            placeholder="Search vacancy"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
            data-testid="search-input"
          />
          <MultipleSelect
            placeholder="All Regions"
            options={regionsWithCounties.map((el) => ({
              label: el.region,
              value: el.region,
            }))}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                regions: values ?? [],
              }));
            }}
            data-testid="regions-select"
          />
          <MultipleSelect
            placeholder="All Levels"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, levels: values ?? [] }));
            }}
            options={jobLevelWithGrades.map((el) => ({
              label: el.level,
              value: el.level,
            }))}
            data-testid="levels-select"
          />
          <MultipleSelect
            placeholder="All Grades"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, grades: values ?? [] }));
            }}
            options={getGradesOfSelectedLevel(filter.levels).map((el) => ({
              label: el,
              value: el,
            }))}
            disabled={!filter.levels.length && !filter.grades.length}
            data-testid="grades-select"
          />
        </div>
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table data-testid="vacancy-table">
            <thead>
              <tr>
                {/* <th
                  className="checkbox-table"
                  data-testid="checkbox-table-header"
                >
                  <input type="checkbox" data-testid="checkbox-select-all" />
                </th> */}
                <th
                  onClick={() => onSelectSort('specialty', setSort)}
                  data-testid="vacancy-column"
                >
                  <div>
                    <label>Vacancy</label>
                    <SortIcon
                      value={sort.key === 'specialty' ? sort.value : ''}
                      data-testid="vacancy-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('client-name', setSort)}
                  data-testid="client-column"
                >
                  <div>
                    <label>Client</label>
                    <SortIcon
                      value={sort.key === 'client-name' ? sort.value : ''}
                      data-testid="client-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('client-firstName', setSort)}
                  data-testid="lead-contact-column"
                >
                  <div>
                    <label>Lead Contact</label>
                    <SortIcon
                      value={sort.key === 'client-firstName' ? sort.value : ''}
                      data-testid="lead-contact-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('user-firstName', setSort)}
                  data-testid="line-manager-column"
                >
                  <div>
                    <label>Line Manager</label>
                    <SortIcon
                      value={sort.key === 'user-firstName' ? sort.value : ''}
                      data-testid="line-manager-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('status', setSort)}
                  data-testid="job-status-column"
                >
                  <div>
                    <label>Job Status</label>
                    <SortIcon
                      value={sort.key === 'status' ? sort.value : ''}
                      data-testid="job-status-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('level', setSort)}
                  data-testid="job-title-column"
                >
                  <div>
                    <label>Job Title</label>
                    <SortIcon
                      value={sort.key === 'level' ? sort.value : ''}
                      data-testid="job-title-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('region', setSort)}
                  data-testid="region-column"
                >
                  <div>
                    <label>Region</label>
                    <SortIcon
                      value={sort.key === 'region' ? sort.value : ''}
                      data-testid="region-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('status', setSort)}
                  data-testid="client-status-column"
                >
                  <div>
                    <label>Client Status</label>
                    <SortIcon
                      value={sort.key === 'status' ? sort.value : ''}
                      data-testid="client-status-sort-icon"
                    />
                  </div>
                </th>
                <th data-testid="actions-column">
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr data-testid="loading-row">
                  <td
                    colSpan={100}
                    className="text-center"
                    data-testid="loading-text"
                  >
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((vacancy) => {
                        return (
                          <tr
                            key={vacancy._id}
                            data-testid={`vacancy-row-${vacancy._id}`}
                          >
                            {/* <td className="checkbox-table">
                              <input
                                type="checkbox"
                                data-testid={`checkbox-vacancy-${vacancy._id}`}
                              />
                            </td> */}
                            <td data-testid={`vacancy-name-${vacancy._id}`}>
                              <div className="name-item">
                                <div>
                                  <div>{vacancy?.specialty}</div>
                                  <div className="email">
                                    {vacancy?.shift
                                      ? vacancy?.shift + ' shift'
                                      : '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td data-testid={`client-name-${vacancy._id}`}>
                              <div className="name-item">
                                <div>
                                  <div>{vacancy?.client?.clientName}</div>
                                  <div className="email">
                                    {vacancy?.client?.trust?.name || '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td data-testid={`lead-contact-${vacancy._id}`}>
                              <div className="name-item">
                                <div>
                                  <div>
                                    {vacancy?.client?.firstName}{' '}
                                    {vacancy?.client?.lastName}
                                  </div>
                                  <div className="email">
                                    {vacancy?.client?.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td data-testid={`line-manager-${vacancy._id}`}>
                              {vacancy.user ? (
                                `${vacancy.user?.firstName} ${vacancy.user?.lastName}`
                              ) : (
                                <Badge
                                  type={'warning'}
                                  data-testid={`unassigned-badge-${vacancy._id}`}
                                >
                                  Unassigned
                                </Badge>
                              )}
                            </td>
                            <td data-testid={`job-status-${vacancy._id}`}>
                              <Badge
                                type={
                                  vacancyStatus[
                                    vacancy.status ?? EJobStatus.Pending
                                  ]
                                }
                                data-testid={`job-status-badge-${vacancy._id}`}
                              >
                                {vacancy.status ?? EJobStatus.Pending}
                              </Badge>
                            </td>
                            <td data-testid={`job-title-${vacancy._id}`}>
                              <div className="name-item">
                                <div>
                                  <div>{vacancy?.level}</div>
                                  <div className="email">{vacancy?.grade}</div>
                                </div>
                              </div>
                            </td>
                            <td data-testid={`region-${vacancy._id}`}>
                              <div className="name-item">
                                <div>
                                  <div>
                                    {vacancy?.client?.address?.region || '-'}
                                  </div>
                                  <div className="email">
                                    {vacancy?.client?.address?.county || '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td data-testid={`client-status-${vacancy._id}`}>
                              <Badge
                                type={
                                  vacancy?.client?.isProfileComplete
                                    ? 'success'
                                    : 'danger'
                                }
                                data-testid={`client-status-badge-${vacancy._id}`}
                              >
                                {vacancy?.client?.isProfileComplete
                                  ? 'Active'
                                  : 'Not Active'}
                              </Badge>
                            </td>
                            <td data-testid={`actions-${vacancy._id}`}>
                              <div className="action-item">
                                <div
                                  className="download-icon"
                                  onClick={() =>
                                    onClickAvailability(vacancy._id)
                                  }
                                  data-testid={`calendar-icon-${vacancy._id}`}
                                >
                                  <CalendarIcon title="Manage vacancy availabilities" />
                                </div>
                                <div
                                  className="view-icon"
                                  onClick={() => onClickView(vacancy._id)}
                                  data-testid={`eye-icon-${vacancy._id}`}
                                >
                                  <EyeIcon />
                                </div>
                                <div
                                  className="edit-icon"
                                  onClick={() => onClickEdit(vacancy)}
                                  data-testid={`edit-icon-${vacancy._id}`}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className="delete-icon"
                                  onClick={() => onDeleteConfirm(vacancy)}
                                  data-testid={`delete-icon-${vacancy._id}`}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr data-testid="no-items-row">
                      <td
                        colSpan={100}
                        className="text-center"
                        data-testid="no-items-text"
                      >
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalEntries={vacanciesList.length}
          data-testid="pagination"
        />
      </CardContainer>
    </>
  );
};
