import React from 'react';
import styled from 'styled-components';
import {
  Chart as ChartJS,
  LineElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  LineElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
);

const ChartContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible;
  z-index: 10;

  .chart-title {
    font-size: 20px;
    text-align: center !important;
  }
  .chart-container {
    z-index: 1;
    position: relative;
  }
`;

export type LineChartConfig = {
  labels: string[];
  backgroundColors?: string[];
  borderColors?: string[];
  title?: string;
  subtitle?: string;
  size?: number;
  displayLegend?: boolean;
  displayCenterValues?: boolean;
};

type LineChartProps = {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
      borderColor: string;
    }[];
  };
  config: LineChartConfig;
  isLoading?: boolean;
};

export const LineChart: React.FC<LineChartProps> = ({
  data,
  config: { title = 'Chart', displayLegend = true },
  isLoading = false,
}) => {
  return (
    <ChartContainer>
      <div className="chart-title" data-testid="chart-title">
        {title}
      </div>
      <div className="chart-container" data-testid="chart-visual">
        <Line
          data={{
            ...data,
            labels: ['0', ...data.labels], // Add '0' to the labels
            datasets: data.datasets.map((dataset) => ({
              ...dataset,
              data: [null, ...dataset.data], // Add null to the data to skip drawing at 0
              tension: 0.4, // Add tension to make the line curvy
            })),
          }}
          options={{
            plugins: {
              legend: {
                display: displayLegend,
                align: 'start',
                position: 'bottom',
                labels: {
                  padding: 10,
                  boxWidth: 10,
                },
              },
            },
            animation: {
              duration: 300,
            },
            layout: {
              padding: {
                top: 10,
                bottom: 30,
              },
            },
            scales: {
              x: {
                ticks: {
                  callback: function (value, index, values) {
                    return data.labels[index - 1]; // Adjust index to skip '0'
                  },
                },
              },
              y: {
                ticks: {
                  callback: function (value, index, values) {
                    return `${Number(value)}%`;
                  },
                },
              },
            },
            onHover(event, elements, chart) {
              (event?.native?.target as HTMLElement).style.cursor =
                elements?.length > 0 ? 'pointer' : 'auto';
            },
          }}
          data-testid="line-chart"
        />
      </div>
    </ChartContainer>
  );
};
