import React, { useEffect, useState } from 'react';
import { CallIcon, EditIcon, LeftArrowIcon, MessageIcon } from '../../../icons';
import {
  ATag,
  AddNewNoteCandidateForm,
  AddToVacancyForm,
  AvailabilityComponent,
  Button,
  CardContainer,
  ComplianceList,
  DragDropUpload,
  Table,
  TitlePage,
  UpdateCandidateForm,
  UserIcon,
  CandidateDetailsGeneral,
  DocumentsListComponent,
  TabPageContainer,
} from '../../../components';
import { CandidateDetailsContainer } from './candidate-details.style';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  formatDateTime,
  uploadCandidateFiles,
} from '../../../utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Availability,
  Candidate,
  Compliance,
  EJobShift,
  EUserRole,
  HostedFile,
  Job,
  Payroll,
  User,
} from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../contexts/auth.context';
import { useModal } from '../../../contexts/side-modal.context';

export enum ECandidateTabs {
  GENERAL = 'general',
  CONTACT = 'contact',
  AVAILABILITY = 'availability',
  PAYROLL = 'payroll',
  DOCUMENTS = 'documents',
  COMPLIANCE = 'compliance',
  NOTES = 'notes',
}

const tabs = [
  ECandidateTabs.GENERAL,
  ECandidateTabs.AVAILABILITY,
  ECandidateTabs.PAYROLL,
  ECandidateTabs.DOCUMENTS,
  ECandidateTabs.COMPLIANCE,
  ECandidateTabs.NOTES,
];

export const CandidateDetailsPage = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(ECandidateTabs.GENERAL);
  const [candidate, setCandidate] = useState<Candidate | null>(null);
  const [documents, setDocuments] = useState<HostedFile[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [vacanciesList, setVacanciesList] = useState<Job[]>([]);
  const [payrollsList, setPayrollsList] = useState<Payroll[]>([]);
  const [compliancesList, setCompliancesList] = useState<Compliance[]>([]);
  const [availabilities, setAvailabilities] = useState<Availability[]>([]);

  const isPayrollBelongsToUser =
    candidate?.recruiter?._id === user?._id || user?.role === EUserRole.Admin;

  const { openModal, closeModal } = useModal();

  const onClickCreateNote = () => {
    openModal({
      title: 'New Note',
      component: (
        <AddNewNoteCandidateForm
          candidate={candidate!}
          onCancel={() => closeModal()}
          onSuccess={() => {
            getCandidateDetails();
            closeModal();
          }}
          data-testid="add-note-form"
        />
      ),
    });
  };

  const onClickEdit = () => {
    openModal({
      title: 'Update Candidate',
      component: (
        <UpdateCandidateForm
          onCancel={() => closeModal()}
          onSuccess={() => {
            getCandidateDetails();
            closeModal();
          }}
          payrollsList={payrollsList}
          usersList={usersList}
          selectedCandidate={candidate!}
          data-testid="update-candidate-form"
        />
      ),
    });
  };

  const onClickAddToVacancy = () => {
    openModal({
      title: 'Add to vacancy',
      component: (
        <AddToVacancyForm
          onCancel={() => closeModal()}
          onSuccess={() => {
            getAvailabilities();
            closeModal();
          }}
          candidate={candidate!}
          vacancies={vacanciesList}
          data-testid="add-to-vacancy-form"
        />
      ),
    });
  };

  const onClickGoBack = () => {
    navigate(-1);
  };

  const handleFileChange = async (files: File[]) => {
    await uploadCandidateFiles(id!, files)
      .then(() => {
        toast.success('Documents uploaded successfully');
        getCandidateDocuments();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickDelete = async (documentId: string) => {
    AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getCandidateDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  const getCandidateDetails = () => {
    AxiosInstance.candidates
      .candidatesControllerGetCandidate(id!)
      .then((response) => {
        setCandidate(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCandidateDocuments = () => {
    AxiosInstance.files
      .filesControllerFindCandidateDocs(id!)
      .then((result) => {
        setDocuments(result.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerFindAll()
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getPayrolls = async () => {
    await AxiosInstance.payrolls
      .payrollsControllerFindAll()
      .then((response) => {
        setPayrollsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getVacancies = async () => {
    AxiosInstance.jobs
      .jobsControllerFindAll()
      .then((response) => {
        setVacanciesList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getComplianceList = async () => {
    AxiosInstance.compliances
      .compliancesControllerFindAll({
        candidateId: id,
      })
      .then((response) => {
        setCompliancesList(response.data.items.filter((el) => el.isActive));
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getAvailabilities = async () => {
    AxiosInstance.availability
      .availabilityControllerFindAllByCandidateId(id!)
      .then((response) => {
        const result = response.data.filter(
          (el) =>
            el.availabilityTime === EJobShift.Day ||
            el.availabilityTime === EJobShift.Night,
        );
        setAvailabilities(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    setSelectedTab(
      (location.pathname.split('/')?.[3] as ECandidateTabs) ||
        ECandidateTabs.GENERAL,
    );
  }, [location]);

  useEffect(() => {
    getCandidateDetails();
    getUsers();
    getPayrolls();
    getVacancies();
    getCandidateDocuments();
    getComplianceList();
    getAvailabilities();
  }, []);

  if (!candidate) {
    return <></>;
  }

  return (
    <>
      <CandidateDetailsContainer data-testid="candidate-details-container">
        <div className="candidate-header" data-testid="candidate-header">
          <div
            className="back-button"
            onClick={onClickGoBack}
            data-testid="back-button"
          >
            <LeftArrowIcon data-testid="left-arrow-icon" />
          </div>
          <TitlePage data-testid="candidate-title">Detail Candidate</TitlePage>
        </div>

        <CardContainer
          className="page-summary-container"
          data-testid="candidate-summary-container"
        >
          <div className="module-name" data-testid="module-name">
            <UserIcon
              firstName={candidate.firstName}
              lastName={candidate.lastName}
              entity="candidate"
              data-testid="user-icon"
            />
            <div className="module-info" data-testid="module-info">
              <div className="name" data-testid="candidate-name">
                {candidate.firstName} {candidate.lastName}
              </div>
              <div className="availability" data-testid="candidate-city">
                {candidate.address?.city}
              </div>
            </div>
          </div>
          <div className="module-contact" data-testid="module-contact-phone">
            <CallIcon data-testid="call-icon" />
            <div>{candidate.phoneNumber}</div>
          </div>
          <div className="module-contact" data-testid="module-contact-email">
            <MessageIcon data-testid="message-icon" />
            <div>{candidate.email}</div>
          </div>
          <div className="actions-container" data-testid="actions-container">
            <Button
              type="primary"
              onClick={() => onClickAddToVacancy()}
              data-testid="add-to-vacancy-button"
            >
              Add to Vacancy
            </Button>

            <Button
              type="primary"
              onClick={() => navigate(`/messages/candidate/${candidate._id}`)}
              data-testid="message-button"
            >
              Message
            </Button>
          </div>
          <div
            className={`edit-container edit-icon ${
              !isPayrollBelongsToUser && 'disabled'
            }`}
            onClick={() => isPayrollBelongsToUser && onClickEdit()}
            data-testid="edit-icon-container"
          >
            <EditIcon data-testid="edit-icon" />
          </div>
        </CardContainer>

        <CardContainer
          className="candidate-information-container"
          data-testid="candidate-info-container"
        >
          <TabPageContainer data-testid="tab-page-container">
            <div className="tab-items-list" data-testid="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item text-capitalize ${
                    el === selectedTab && 'active'
                  }`}
                  onClick={() => navigate(`/${ERoute.CANDIDATES}/${id}/${el}`)}
                  data-testid={`tab-item-${el}`}
                >
                  {el}
                </ATag>
              ))}
            </div>
            <div
              className="tab-content-container"
              data-testid="tab-content-container"
            >
              {selectedTab === ECandidateTabs.GENERAL && (
                <CandidateDetailsGeneral
                  candidate={candidate}
                  data-testid="general-tab-content"
                />
              )}
              {selectedTab === ECandidateTabs.AVAILABILITY && (
                <AvailabilityComponent
                  availabilities={availabilities}
                  getAvailabilities={getAvailabilities}
                  data-testid="availability-tab-content"
                />
              )}
              {selectedTab === ECandidateTabs.PAYROLL && (
                <div className="info-card" data-testid="payroll-tab-content">
                  <div
                    className="info-card-title"
                    data-testid="payroll-card-title"
                  >
                    Bank Account
                  </div>
                  <hr />
                  <div
                    className="info-card-content row"
                    data-testid="payroll-card-content"
                  >
                    <div className="col-md-6 row" data-testid="bank-name-row">
                      <div className="info-item-title col-4">Bank Name</div>
                      <div className="info-item-data col-8">
                        {candidate.payrollProvider?.bank?.name || '-'}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="account-name-row"
                    >
                      <div className="info-item-title col-4">Account Name</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.bank?.accountName || '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="branch-row">
                      <div className="info-item-title col-4">Branch</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.address?.city || '-'}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="account-number-row"
                    >
                      <div className="info-item-title col-4">
                        Account Number
                      </div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.bank?.accountNumber || '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="type-row">
                      <div className="info-item-title col-4">Type</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.employmentType || '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="sort-code-row">
                      <div className="info-item-title col-4">Sort Code</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.bank?.sortNumber || '-'}
                      </div>
                    </div>
                  </div>
                  <br />
                  <Table data-testid="payroll-provider-table">
                    <thead>
                      <tr>
                        <th>Payroll Providers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payrollsList.map((el) => {
                        return (
                          <tr data-testid="payroll-provider-row">
                            <td>
                              <div
                                className="payroll-name-checkbox"
                                data-testid="payroll-name-checkbox"
                              >
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={
                                    el._id === candidate?.payrollProvider?._id
                                  }
                                  data-testid="payroll-checkbox"
                                />
                                <div>{el.payrollProviderName}</div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
              {selectedTab === ECandidateTabs.DOCUMENTS && (
                <>
                  <div
                    className="info-card"
                    data-testid="documents-tab-content"
                  >
                    <div
                      className="info-card-title"
                      data-testid="personal-documents-title"
                    >
                      Personal Documents
                    </div>
                    <hr />
                    <div
                      className="data-table-container"
                      data-testid="documents-table-container"
                    >
                      <DocumentsListComponent
                        documents={documents}
                        onClickDelete={onClickDelete}
                        getDocuments={getCandidateDocuments}
                        data-testid="documents-list"
                      />
                    </div>
                  </div>
                  <div
                    className="info-card"
                    data-testid="drag-drop-upload-container"
                  >
                    <DragDropUpload
                      onUpload={handleFileChange}
                      data-testid="drag-drop-upload"
                    />
                  </div>
                </>
              )}
              {selectedTab === ECandidateTabs.COMPLIANCE && (
                <div className="info-card" data-testid="compliance-tab-content">
                  <div
                    className="info-card-title"
                    data-testid="compliance-title"
                  >
                    Compliances List
                  </div>
                  <hr />
                  <div
                    className="data-table-container"
                    data-testid="compliance-table-container"
                  >
                    <ComplianceList
                      compliancesList={compliancesList}
                      data-testid="compliance-list"
                    />
                  </div>
                </div>
              )}
              {selectedTab === ECandidateTabs.NOTES && (
                <>
                  <div className="info-card" data-testid="notes-tab-content">
                    <div
                      className="info-card-header"
                      data-testid="notes-header"
                    >
                      <div
                        className="info-card-title"
                        data-testid="notes-title"
                      >
                        Notes Info
                      </div>
                      <Button
                        type="primary"
                        onClick={() => onClickCreateNote()}
                        data-testid="add-notes-button"
                      >
                        Add Notes
                      </Button>
                    </div>
                    <hr />
                    {candidate.notes?.length ? (
                      <>
                        {candidate.notes.map((el) => (
                          <div className="info-card" data-testid="note-item">
                            <div
                              className="info-card-header"
                              data-testid="note-header"
                            >
                              <div
                                className="info-card-content w-100"
                                data-testid="note-content"
                              >
                                <div
                                  className="info-item-title notes-header-container"
                                  data-testid="note-header-container"
                                >
                                  <div>
                                    {el.user.firstName} {el.user.lastName}
                                  </div>
                                  <div>{formatDateTime(el.createdAt)}</div>
                                </div>
                                <div
                                  className="info-item-data notes-container"
                                  data-testid="note-text"
                                >
                                  {el.text}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div
                        className="w-100 text-center"
                        data-testid="no-notes-found"
                      >
                        No item found
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </TabPageContainer>
        </CardContainer>
      </CandidateDetailsContainer>
    </>
  );
};
