import { ENotificationType, Job, Notification } from '../backend/careo-api';
import { isCRMApp } from '../environment/app.type';
import { ERoute } from './routes.utils';

const crmNotifications = [
  ENotificationType.CANDIDATE_REJECT_PLACEMENT,
  ENotificationType.CANDIDATE_MATCH,
  ENotificationType.AI_VACANCY_CREATED,
  ENotificationType.COMPLIANCE_REQUIRED,
  ENotificationType.COMPLIANCE_WARNING_EXPIRATION,
  ENotificationType.COMPLIANCE_EXPIRED,
];

const complianceNotifications = [
  ENotificationType.COMPLIANCE_REQUIRED,
  ENotificationType.COMPLIANCE_WARNING_EXPIRATION,
  ENotificationType.COMPLIANCE_EXPIRED,
  ENotificationType.COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_IN_PROCESS,
  ENotificationType.COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_SUCCESS,
  ENotificationType.COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_FAILED,
  ENotificationType.COMPLIANCE_PRO_REG_VALIDATION_IN_PROCESS,
  ENotificationType.COMPLIANCE_PRO_REG_VALIDATION_SUCCESS,
  ENotificationType.COMPLIANCE_PRO_REG_VALIDATION_FAILED,
  ENotificationType.COMPLIANCE_DBS_CHECK_VALIDATION_IN_PROCESS,
  ENotificationType.COMPLIANCE_DBS_CHECK_VALIDATION_SUCCESS,
  ENotificationType.COMPLIANCE_DBS_CHECK_VALIDATION_FAILED,
  ENotificationType.COMPLIANCE_OCCUPATIONAL_HEALTH_CLEARANCE_CHECK_VALIDATION_IN_PROCESS,
  ENotificationType.COMPLIANCE_OCCUPATIONAL_HEALTH_CLEARANCE_CHECK_VALIDATION_SUCCESS,
  ENotificationType.COMPLIANCE_OCCUPATIONAL_HEALTH_CLEARANCE_CHECK_VALIDATION_FAILED,
];

export const acceptedNotificationOnly = isCRMApp
  ? crmNotifications
  : complianceNotifications;

export const getNotificationData = (notification: Notification) => {
  const data = notification.data as any;
  let title = '';
  let description = '';
  let url = '';

  if (!acceptedNotificationOnly.includes(notification.type)) {
    return;
  }

  switch (notification.type) {
    case ENotificationType.CANDIDATE_REJECT_PLACEMENT:
      title = 'Placement Rejected';
      description = `${notification?.candidate?.firstName} ${
        notification?.candidate?.lastName
      } has rejected ${(data?.job as Job)?.grade} placement.`;
      url = `/${ERoute.PLACEMENTS}/${data?.placement?._id}`;
      break;
    case ENotificationType.CANDIDATE_MATCH:
      title = 'New Candidate Match';
      description = `${notification?.candidate?.firstName} ${notification?.candidate?.lastName} has been matched to the open ${data?.job?.specialty} vacancy.`;
      url = `/${ERoute.VACANCIES}/${data?.job?._id}/applications`;
      break;
    case ENotificationType.AI_VACANCY_CREATED:
      title = 'New AI Vacancy Created';
      description = `${data?.vacancy?.name} vacancy for ${data?.vacancy?.client}.`;
      url = `/${ERoute.VACANCIES}/${data?.job?._id}`;
      break;

    case ENotificationType.COMPLIANCE_REQUIRED:
      title = 'Compliance Required';
      description = `${notification?.candidate?.firstName} ${notification?.candidate?.lastName} needs to complete compliance before the start date.`;
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      break;
    case ENotificationType.COMPLIANCE_WARNING_EXPIRATION:
      title = 'Compliance Expiration Warning';
      description = `${notification?.candidate?.firstName} ${notification?.candidate?.lastName}'s compliance will expire soon.`;
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      break;
    case ENotificationType.COMPLIANCE_EXPIRED:
      title = 'Compliance Expired';
      description = `${notification?.candidate?.firstName} ${notification?.candidate?.lastName}'s compliance has expired.`;
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      break;
    // EMPLOYMENT HISTORY
    case ENotificationType.COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_IN_PROCESS:
      title = 'Employment History documents';
      description = 'Validation is in process ...';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      break;
    case ENotificationType.COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_SUCCESS:
      title = 'Employment History Validation';
      description = data?.message || '';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      break;
    case ENotificationType.COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_FAILED:
      title = 'Failed to validate Employment History documents';
      description = data?.message || '';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      break;
    // PROFESSIONAL REGISTRATION
    case ENotificationType.COMPLIANCE_PRO_REG_VALIDATION_IN_PROCESS:
      title = 'Professional Registry Validation';
      description = 'Validation is in process ...';
      url = '';
      break;
    case ENotificationType.COMPLIANCE_PRO_REG_VALIDATION_SUCCESS:
      title = 'Professional Registry Validation';
      description = data?.message || '';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      break;
    case ENotificationType.COMPLIANCE_PRO_REG_VALIDATION_FAILED:
      title = 'Professional Registry Validation';
      description = data?.message || '';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      break;
    // DBS CHECK
    case ENotificationType.COMPLIANCE_DBS_CHECK_VALIDATION_IN_PROCESS:
      title = 'DBS check validation';
      description = 'Validation is in process ...';
      url = '';
      break;
    case ENotificationType.COMPLIANCE_DBS_CHECK_VALIDATION_SUCCESS:
      title = 'DBS check validation';
      description = data?.message || '';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      break;
    case ENotificationType.COMPLIANCE_DBS_CHECK_VALIDATION_FAILED:
      title = 'DBS check validation';
      description = data?.message || '';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      break;
    // OCCUPATION HEALTH CLEARANCE
    case ENotificationType.COMPLIANCE_OCCUPATIONAL_HEALTH_CLEARANCE_CHECK_VALIDATION_IN_PROCESS:
      title = 'Occupation health clearance Validation';
      description = 'Validation is in process ...';
      url = '';
      break;
    case ENotificationType.COMPLIANCE_OCCUPATIONAL_HEALTH_CLEARANCE_CHECK_VALIDATION_SUCCESS:
      title = 'Occupation health clearance Validation';
      description = data?.message || '';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      break;
    case ENotificationType.COMPLIANCE_OCCUPATIONAL_HEALTH_CLEARANCE_CHECK_VALIDATION_FAILED:
      title = 'Occupation health clearance Validation';
      description = data?.message || '';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      break;

    default:
      title = '';
      description = '';
  }

  if (!title) {
    return null;
  }

  return {
    _id: notification._id,
    title,
    description,
    url,
    isNew: !notification.viewedDate,
    createdDate: new Date(notification.createdDate),
  };
};

export type TNotificationUI = {
  _id: string;
  title: string;
  description: string;
  url: string;
  isNew: boolean;
  createdDate: Date;
};
