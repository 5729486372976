import styled from 'styled-components';
import { breakpoint } from '../../constants';

export const ComplianceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .compliance-info-container {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .compliance-status {
      display: flex;
      flex-direction: row !important;
      font-weight: 400;
      gap: 5px;
      font-size: 14px;
      &.buttons-container {
        display: flex;
        align-items: flex-end;
      }

      &.validated-by-ai {
        flex-direction: row !important;
        justify-content: flex-end;
      }
    }

    .compliance-progress-container {
      display: flex;
      align-items: center;
      gap: 5px;
      .setting-button-container {
        cursor: pointer;
        svg {
          path {
            stroke: #adadad;
          }
        }
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    gap: 10px;

    .compliance-info-container {
      .compliance-status {
        font-size: 10px;
      }
    }
  }
`;

export const CandidateComplianceDetailsContainer = styled.div`
  .candidate-header {
    display: flex;
    align-items: center;
    gap: 5px;
    .back-button {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background-color: #f1f2f4;
      }
      svg {
        path {
          fill: #111827;
        }
      }
    }
  }
  .candidate-information-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
    flex: 5;
    max-width: 100%;
  }
  .compliance-updates-container {
    flex: 2;
    height: 100%;
  }
  .compliance-list-container {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    .candidate-header {
      .back-button {
        width: 20px;
        height: 20px;
      }
    }
    .candidate-information-container {
      padding: 12px;
      gap: 12px;
    }
    .compliance-updates-container {
    }
    .compliance-list-container {
      gap: 12px;
    }
  }
`;
