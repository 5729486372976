import * as yup from 'yup';
import { regex } from '../regex.utils';
import { weekWorkingHoursDtoSchema } from './core-hours.validator';

const clientSchema = yup.object().shape({
  clientName: yup
    .string()
    .required('Client name is required')
    .matches(regex.name, 'Client name is not valid'),

  trustId: yup.string(),

  title: yup.string().required('Title is required'),
  firstName: yup
    .string()
    .required('First name is required')
    .matches(
      regex.name,
      'First Name must contain only letters, spaces, apostrophes, and hyphens',
    ),
  lastName: yup
    .string()
    .required('Last name is required')
    .matches(
      regex.name,
      'Last Name must contain only letters, spaces, apostrophes, and hyphens',
    ),
  jobTitle: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(regex.name, 'Job title is not valid'),
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  phoneNumber: yup
    .string()
    .matches(
      regex.phoneNumber,
      'Phone number must start with a + sign, followed by 9 to 15 digits',
    )
    .required('Phone number is required.'),
  workPhoneNumber: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(
      regex.phoneNumber,
      'Phone number must start with a + sign, followed by 9 to 15 digits',
    ),
  faxNumber: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(/^\+\d+$/, 'Fax should start with + sign and contain only digits')
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars'),
  website: yup
    .string()
    .url()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9]+\.)*[a-zA-Z0-9][a-zA-Z0-9-]+(\.[a-zA-Z]{2,})?(:\d{1,5})?(\/\S*)?$/,
      'Invalid website URL. Please enter a valid URL',
    ),
  address: yup.object().shape({
    street: yup
      .string()
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    city: yup
      .string()
      .matches(regex.name, 'City is not valid')
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    county: yup
      .string()
      .min(1, 'County is required')
      .matches(regex.name, 'County is not valid')
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    region: yup
      .string()
      .min(1, 'Region is required')
      .matches(regex.name, 'Region is not valid')
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    country: yup
      .string()
      .min(1, 'Country is required')
      .matches(regex.name, 'Country is not valid')
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    zip: yup
      .string()
      .matches(regex.name, 'ZIP code is not valid')
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
  }),
  userId: yup.string().required('Recruiter is required'),
  bank: yup.object().shape({
    name: yup
      .string()
      .matches(regex.name, 'Bank name is not valid')
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    accountName: yup
      .string()
      .matches(regex.name, 'Account name is not valid')
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    accountNumber: yup
      .string()
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    sortNumber: yup
      .string()
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
  }),
  whatsappId: yup.string(),
});

const trustSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(regex.name, 'Name is not valid'),

  description: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(regex.name, 'Description name is not valid'),
});

const rateSchema = yup.object().shape({
  type: yup.string(),
  specialty: yup.string(),

  pricing: yup
    .array()
    .of(
      yup.object().shape({
        corePrice: yup
          .number()
          .typeError('Core price must be a valid number.')
          // .positive('Core price must be positive')
          .default(0),
        unSocialPrice: yup
          .number()
          .typeError('Unsocial price must be a valid number.')
          // .positive('Unsocial price must be positive')
          .default(0),
        onCallActivePrice: yup
          .number()
          .typeError('On-call active price must be a valid number.')
          // .positive('On-call active price must be positive')
          .default(0),
        onCallPassivePrice: yup
          .number()
          .typeError('On-call passive price must be a valid number.')
          // .positive('On-call passive price must be positive')
          .default(0),
        charge: yup
          .number()
          .typeError('Charge must be a valid number.')
          // .positive('Charge must be positive')
          .required('Charge is required'),
        // .test(
        //   'charge-must-be-greater-than-rate',
        //   'Charge amount must be greater than any rate amount',
        //   function (value) {
        //     const {
        //       corePrice,
        //       unSocialPrice,
        //       onCallActivePrice,
        //       onCallPassivePrice,
        //     } = this.parent;
        //     return (
        //       Math.max(
        //         corePrice,
        //         unSocialPrice,
        //         onCallActivePrice,
        //         onCallPassivePrice,
        //       ) < value
        //     );
        //   },
        // ),
      }),
    )
    .required('Pricing is required'),

  coreHours: weekWorkingHoursDtoSchema.required('Core hours are required'),
});

export { clientSchema, trustSchema, rateSchema };
