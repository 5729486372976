import styled from 'styled-components';

export const CoreHours = styled.div`
  gap: 10px !important;
  display: flex;
  justify-content: center;

  .core-hours-header {
    background-color: #fafafa;
    font-size: 14px;
    height: 56px;
    display: flex;
    align-items: center;
    font-size: 13px;
    border-radius: 5px;
  }

  .box,
  .non-working-day {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .non-working-day {
    font-size: 12px;
  }

  .days {
    min-height: 55px;
  }
`;

export const BandsPricing = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px !important;
  .item {
    gap: 10px !important;
    display: flex;
    justify-content: center;
  }

  .band-header {
    background-color: #fafafa;
    font-size: 14px;
    height: 56px;
    display: flex;
    align-items: center;
    font-size: 13px;
    border-radius: 5px;
  }

  .box,
  .non-working-day {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .non-working-day {
    font-size: 12px;
  }

  .days {
    min-height: 55px;
  }
`;

export const NestedRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px !important;

  div {
    border-bottom: 1px solid #f1f2f4;
    padding: 10px;
  }

  div:last-child {
    border-bottom: none;
  }
`;
