import React, { useState } from 'react';
import styled from 'styled-components';
import { DeleteIcon, EditIcon } from '../../icons';

const AccordionContainer = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;

  .accordion-header {
    display: flex;
    justify-content: space-between;
    button {
      width: fit-content;
    }
  }

  &.primary-item {
    border-radius: 16px;
    border: 1px solid #f1f2f4;
    background: #fff;
    > .accordion-item > .accordion-header {
      border-bottom: 1px solid #f1f2f4 !important;
    }
    .accordion-title {
      font-weight: bolder;
      font-size: 1rem;
      padding: 24px;
    }

    .accordion-button {
      border: unset !important;
      padding: 24px;
    }

    > h2 > .accordion-button {
      font-size: 20px;
    }

    .accordion-body {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  &.secondary-item {
    .title,
    .accordion-button {
      border: unset !important;
      padding: 2px 0px;
    }
    .accordion-body {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }

  .accordion-item {
    .accordion-collapse {
      margin: 24px;
    }
    .accordion-body {
      padding: unset;
    }

    .secondary-item .accordion-collapse {
      margin: 24px 0;
    }

    .accordion-collapse {
      border: unset;
    }

    .action-item {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    .accordion-button {
      width: 30px;
      height: 30px;
      padding: unset;
      margin-right: 24px;
    }

    .action-item {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      > div {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 10px;
        cursor: pointer;
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        svg {
          path {
            fill: white;
          }
        }
        &.view-icon {
          background-color: #27a376;
        }
        &.edit-icon {
          background-color: #5e7cad;
        }
        &.more-icon {
          background-color: #2f78ee;
        }
        &.download-icon {
          background-color: #2f50ee;
        }
        &.delete-icon {
          background-color: #e03137;
        }

        &:hover {
          opacity: 0.9;
        }
        &.disabled {
          cursor: not-allowed;
          color: gray;
          border: none;
          background-color: #e9ecef;
          svg > path {
            fill: #a0aec0;
          }
        }
      }
    }
  }

  &.info-card {
    border: unset;
    padding: unset;
  }
`;

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
  isOpenByDefault?: boolean;
  onClickDelete?: () => void;
  onClickEdit?: () => void;
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  className = '',
  isOpenByDefault = false,
  onClickDelete,
  onClickEdit,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  const toggleAccordion = async () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <AccordionContainer className={className} data-testid="accordion">
      <div className="accordion-item" data-testid="accordion-item">
        <h2 className="accordion-header" data-testid="accordion-header">
          <div className="accordion-title">{title}</div>
          <div className="action-item">
            {onClickEdit && (
              <div
                className="edit-icon"
                onClick={() => onClickEdit()}
                data-testid={`edit-icon`}
              >
                <EditIcon />
              </div>
            )}
            {onClickDelete && (
              <div
                className="delete-icon"
                onClick={() => {
                  onClickDelete();
                }}
                data-testid={`delete-icon`}
              >
                <DeleteIcon />
              </div>
            )}

            <button
              className={`accordion-button ${isOpen ? '' : 'collapsed'}`}
              type="button"
              onClick={toggleAccordion}
              aria-expanded={isOpen}
              data-testid="accordion-button"
            ></button>
          </div>
        </h2>
        <div
          className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
          aria-labelledby="accordion-header"
          data-testid="accordion-content"
        >
          <div className="accordion-body">{children}</div>
        </div>
      </div>
    </AccordionContainer>
  );
};
