import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { HeaderContainer } from './header.style';
import {
  AppIcon,
  DashboardIcon,
  DropdownIcon,
  MailIcon,
  MessageIcon,
  NewSmallNotificationRedIcon,
  NotificationIcon,
} from '../../../icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth.context';
import { NewNotificationsList } from '../../notification';
import { Bounce, toast } from 'react-toastify';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  getTimeAgo,
  socket,
} from '../../../utils';
import {
  Notification,
  EWebsocketType,
  SocketEmailEvent,
  SocketMessageEvent,
  BOX_TYPE,
} from '../../../backend/careo-api';
import { Button, UserIcon } from '../../ui';
import { isCRMApp } from '../../../environment/app.type';
import {
  TNotificationUI,
  getNotificationData,
} from '../../../utils/notifcations.utils';
import { useLocation } from 'react-router-dom';
import { useModal } from '../../../contexts/side-modal.context';

interface HeaderProps {
  isSidebarActive: boolean;
  setIsSidebarActive: Dispatch<SetStateAction<boolean>>;
}

export const Header = ({
  isSidebarActive,
  setIsSidebarActive,
}: HeaderProps) => {
  const { logout, user } = useAuth();
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const location = useLocation();

  const [notificationsList, setNotificationsList] = useState<TNotificationUI[]>(
    [],
  );
  const [isNewNotification, setIsNewNotification] = useState(false);
  const smallNotificationList = useMemo(
    () => notificationsList.slice(0, 3),
    [notificationsList],
  );

  const openNotificationSidebar = () => {
    openModal({
      title: 'Updates',
      component: (
        <NewNotificationsList
          setIsOpen={() => closeModal()}
          notifications={notificationsList}
          onClickNotificationItem={onClickNotificationItem}
          getNotifications={getNotifications}
          data-testid="new-notifications-list"
        />
      ),
    });
  };

  const getNotifications = async () => {
    await AxiosInstance.notifications
      .notificationsControllerFindAll()
      .then((response) => {
        const notificationsResponse = response.data.items;
        const result = notificationsResponse
          .map((el) => getNotificationData(el))
          .filter((el) => el?.title) as TNotificationUI[];

        setIsNewNotification(result.some((el) => el.isNew));
        setNotificationsList(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickNotificationItem = async (notificationUI: TNotificationUI) => {
    closeModal();
    navigate(notificationUI.url);
    await AxiosInstance.notifications
      .notificationsControllerViewNotification(notificationUI._id)
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    getNotifications();
  };

  const onClickHome = () => {
    navigate('/');
  };

  useEffect(() => {
    const notificationHandler = (...data: any) => {
      getNotifications();

      const receivedNotifications = (data ?? []) as Notification[];

      const result = receivedNotifications
        .map((el) => getNotificationData(el))
        .filter((el) => el?.title) as TNotificationUI[];

      result.map((el) => {
        toast.info(
          <div className="">
            <div className="notification-title">{el.title}</div>
            <div className="notification-description">{el.description}</div>
          </div>,
          {
            position: 'bottom-right',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: 'light',
            transition: Bounce,
            onClick: () => {
              navigate(el.url);
            },
          },
        );
        return el;
      });
    };

    const emailHandler = (emailData: SocketEmailEvent) => {
      const { from, messageId } = emailData;

      toast.info(
        <div className="">
          <div className="notification-title">New email received</div>
          <div className="notification-description">from : {from}</div>
        </div>,
        {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
          icon: <MailIcon />,
          onClick: () => {
            navigate(`/${ERoute.EMAILS}/${BOX_TYPE.INBOX}/${messageId}`);
          },
        },
      );
    };

    const messageHandler = (messageData: SocketMessageEvent) => {
      const { member, memberType } = messageData;

      if (!location.pathname.includes('/messages')) {
        toast.info(
          <div className="">
            <div className="notification-title">New message received</div>
            <div className="notification-description">
              from : {member.firstName} {member.lastName}
            </div>
          </div>,
          {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: 'light',
            icon: <MessageIcon />,
            transition: Bounce,
            onClick: () => {
              navigate(`/${ERoute.MESSAGES}/${memberType}/${member._id}`);
            },
          },
        );
      }
    };

    socket.on(EWebsocketType.Notification, notificationHandler);
    socket.on(EWebsocketType.Email, emailHandler);
    socket.on(EWebsocketType.Message, messageHandler);

    return () => {
      socket.off(EWebsocketType.Notification, notificationHandler);
      socket.off(EWebsocketType.Email, emailHandler);
      socket.off(EWebsocketType.Message, messageHandler);
    };
  }, [socket]);

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <HeaderContainer
        id="header"
        isSidebarActive={isSidebarActive}
        data-testid="header-container" // Added test ID for HeaderContainer
      >
        <div className="left-header" data-testid="left-header">
          {isCRMApp ? (
            <div
              className="toggle-sidebar"
              onClick={() => setIsSidebarActive((prev) => !prev)}
              data-testid="toggle-sidebar" // Added test ID for sidebar toggle
            >
              {isSidebarActive ? (
                <i
                  className="bx bx-menu bx-x"
                  id="header-toggle"
                  data-testid="toggle-icon-close"
                ></i> // Added test ID for close icon
              ) : (
                <i
                  className="bx bx-menu"
                  id="header-toggle"
                  data-testid="toggle-icon-open"
                ></i> // Added test ID for open icon
              )}
            </div>
          ) : (
            <div
              onClick={onClickHome}
              style={{ cursor: 'pointer' }}
              data-testid="home-icon"
            >
              <AppIcon />
            </div>
          )}
        </div>

        <div className="right-header" data-testid="right-header">
          <div
            className="notification-modal-button"
            onClick={openNotificationSidebar}
            data-testid="notification-button" // Added test ID for notification button
          >
            <NotificationIcon
              hasNewNotification={isNewNotification}
              data-testid="notification-icon"
            />
          </div>

          <div
            className="dropdown dropdown-notification"
            data-testid="notification-dropdown"
          >
            <div
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="notification-dropdown-button"
              data-testid="notification-dropdown-button"
            >
              <NotificationIcon hasNewNotification={isNewNotification} />
            </div>

            <div
              className="dropdown-menu notifications-container"
              data-testid="notification-dropdown-menu"
            >
              <div
                className="notification-header"
                data-testid="notification-header"
              >
                Notification
              </div>
              {smallNotificationList.length ? (
                <>
                  <div
                    className="notification-content-list"
                    data-testid="notification-list"
                  >
                    {smallNotificationList.map((el) => {
                      return (
                        <div
                          className="notification-item"
                          onClick={() => onClickNotificationItem(el)}
                          key={el?._id}
                          data-testid={`notification-item-${el?._id}`} // Added test ID for each notification item
                        >
                          <div className="notification-item-top">
                            <div
                              className="notification-title"
                              data-testid="notification-title"
                            >
                              {el?.title}
                            </div>
                            <div
                              className="notification-time"
                              data-testid="notification-time"
                            >
                              {getTimeAgo(el.createdDate)}
                            </div>
                          </div>
                          <div className="notification-item-bottom">
                            <div
                              className="notification-description"
                              data-testid="notification-description"
                            >
                              {el?.description}
                            </div>
                            {el.isNew && (
                              <NewSmallNotificationRedIcon data-testid="new-notification-icon" />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="notification-action">
                    <Button
                      type="primary"
                      onClick={openNotificationSidebar}
                      data-testid="show-all-notifications-button"
                    >
                      Show All Notifications
                    </Button>
                  </div>
                </>
              ) : (
                <div data-testid="no-notifications">No notifications</div>
              )}
            </div>
          </div>

          {!isCRMApp && (
            <div
              className="icon-container"
              onClick={() => navigate('/settings')}
              data-testid="settings-icon"
            >
              <DashboardIcon />
            </div>
          )}

          <div
            className="icon-container"
            onClick={() => navigate('/emails')}
            data-testid="emails-icon"
          >
            <MailIcon />
          </div>

          <div
            className="icon-container"
            onClick={() => navigate('/messages')}
            data-testid="messages-icon"
          >
            <MessageIcon />
          </div>

          <UserIcon
            firstName={user?.firstName ?? ''}
            lastName={user?.lastName ?? ''}
            entity="user"
            data-testid="user-icon"
          />

          <div className="dropdown" data-testid="user-dropdown">
            <div
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-testid="user-dropdown-button"
            >
              <DropdownIcon />
            </div>
            <ul className="dropdown-menu" data-testid="user-dropdown-menu">
              <li
                className="dropdown-item"
                onClick={() => logout()}
                data-testid="logout-item"
              >
                Logout
              </li>
            </ul>
          </div>
        </div>
      </HeaderContainer>
    </>
  );
};
