import React, { useEffect, useState } from 'react';
import {
  Select,
  TitleCard,
  FieldText,
  Button,
  RadioGroup,
  FormContainer,
  MultipleSelect,
} from '../ui';
import {
  countriesWithRegions,
  genders,
  nationalities,
  regionsWithCounties,
  titles,
  jobLevelWithGrades,
  specialtiesList,
} from '../../constants';
import { useForm } from 'react-hook-form';
import {
  CreateCandidateDto,
  EUserRole,
  Payroll,
  User,
} from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  candidateSchema,
} from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { handleFormsServerErrors } from '../../utils';
import { useAuth } from '../../contexts/auth.context';
import { isCRMApp } from '../../environment/app.type';
import { PlusIcon } from '../../icons';
import { Accordion } from '../ui/accordion';

type NewCandidateFormProps = {
  usersList: User[];
  payrollsList: Payroll[];
  onCancel: () => void;
  onSuccess: () => void;
};

export const NewCandidateForm = ({
  usersList,
  payrollsList,
  onCancel,
  onSuccess,
}: NewCandidateFormProps) => {
  const { user } = useAuth();
  const isAdmin = user?.role === EUserRole.Admin;

  const {
    register,
    getValues,
    handleSubmit,
    watch,
    setValue,
    setError,
    setFocus,
    formState: { errors },
  } = useForm<CreateCandidateDto>({
    resolver: yupResolver(candidateSchema as any),
  });
  const formValues = watch();

  const recruitersList = usersList.filter((el) =>
    [EUserRole.Admin, EUserRole.Recruiter, EUserRole.Manager].includes(
      el.role as EUserRole,
    ),
  );

  const officersList = usersList.filter((el) =>
    [EUserRole.Admin, EUserRole.Officer, EUserRole.Manager].includes(
      el.role as EUserRole,
    ),
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    AxiosInstance.candidates
      .candidatesControllerCreateCandidate(values)
      .then(() => {
        toast.success('Candidate added successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (
      user &&
      isCRMApp &&
      (user.role === EUserRole.Recruiter || user.role === EUserRole.Manager)
    ) {
      setValue('recruiterId', user._id);
    }
    if (
      user &&
      !isCRMApp &&
      (user.role === EUserRole.Officer || user.role === EUserRole.Manager)
    ) {
      setValue('officerId', user._id);
    }
  }, [user]);

  return (
    <FormContainer data-testid="form-container">
      {isCRMApp ? (
        <Select
          disabled={!isAdmin}
          placeholder="Select recruiter"
          required
          label={'Recruiter'}
          options={recruitersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          }))}
          value={!isAdmin ? user?._id : undefined}
          register={register('recruiterId')}
          error={errors.recruiterId}
          data-testid="recruiter-select"
        />
      ) : (
        <Select
          disabled={!isAdmin}
          placeholder="Select Officer"
          required
          label={'Officer'}
          options={officersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          }))}
          value={!isAdmin ? user?._id : undefined}
          register={register('officerId')}
          error={errors.officerId}
          data-testid="officer-select"
        />
      )}
      <TitleCard data-testid="personal-details-title">
        Personal Details
      </TitleCard>
      <div className="row" data-testid="personal-details-row">
        <div className="col-md-2" data-testid="title-select-container">
          <Select
            placeholder="Title"
            label="Title"
            required
            options={titles.map((el) => ({ label: el, value: el }))}
            register={register('title')}
            error={errors.title}
            withoutChip
            data-testid="title-select"
          />
        </div>
        <div className="col-md-5" data-testid="first-name-container">
          <FieldText
            placeholder="Enter here ..."
            label="First Name"
            required
            register={register('firstName')}
            error={errors.firstName}
            data-testid="first-name-field"
          />
        </div>
        <div className="col-md-5" data-testid="last-name-container">
          <FieldText
            placeholder="Enter here ..."
            label="Last Name"
            required
            register={register('lastName')}
            error={errors.lastName}
            data-testid="last-name-field"
          />
        </div>
      </div>
      <div className="row" data-testid="contact-details-row">
        <div className="col-md-6" data-testid="email-container">
          <FieldText
            placeholder="Enter here ..."
            label="Email"
            required
            register={register('email')}
            error={errors.email}
            data-testid="email-field"
          />
        </div>
        <div className="col-md-6" data-testid="mobile-phone-container">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.phoneNumber}
            placeholder="+442071234567"
            label="Mobile Phone Number"
            required
            register={register('phoneNumber')}
            error={errors.phoneNumber}
            data-testid="mobile-phone-field"
          />
        </div>
      </div>
      <div className="row" data-testid="phone-numbers-row">
        <div className="col-md-6" data-testid="home-phone-container">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.homePhoneNumber}
            placeholder="+442071234567"
            label="Home Phone Number"
            error={errors.homePhoneNumber}
            register={register('homePhoneNumber')}
            data-testid="home-phone-field"
          />
        </div>
        <div className="col-md-6" data-testid="work-phone-container">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.workPhoneNumber}
            placeholder="+442071234567"
            label="Work Phone Number"
            error={errors.workPhoneNumber}
            register={register('workPhoneNumber')}
            data-testid="work-phone-field"
          />
        </div>
      </div>
      <div className="row" data-testid="dob-and-nationality-row">
        <div className="col-md-6" data-testid="dob-container">
          <FieldText
            placeholder="Enter here ..."
            label="Date of Birth"
            type="date"
            register={register('birthDate')}
            error={errors.birthDate}
            max={new Date().toJSON().split('T')[0]}
            data-testid="dob-field"
          />
        </div>
        <div className="col-md-6" data-testid="nationality-container">
          <Select
            placeholder="Enter here ..."
            label="Nationality"
            options={nationalities.map((el) => ({ label: el, value: el }))}
            register={register('nationality')}
            error={errors.nationality}
            data-testid="nationality-select"
          ></Select>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <TitleCard data-testid="personal-details-title">
          Professional Registration Identifiers
        </TitleCard>
        <div className="action-item">
          <div
            className="more-icon"
            onClick={() => {
              setValue('professionalRegistrations', [
                ...(formValues.professionalRegistrations ?? []),
                { authority: '', number: '' },
              ]);
            }}
            data-testid={`more-icon`}
          >
            <PlusIcon />
          </div>
        </div>
      </div>

      {formValues.professionalRegistrations?.map((item, index) => {
        return (
          <Accordion
            title={`Identifier #${index + 1}`}
            className="primary-item"
            key={`Identifier-${index}`}
            // data-testid={`accordion-item-identifier-${index}`}
            isOpenByDefault={true}
            onClickDelete={() => {
              const list = [...(formValues.professionalRegistrations ?? [])];
              list?.splice(index, 1);
              setValue('professionalRegistrations', list);
            }}
          >
            <div className="row">
              <div className="col-md-6">
                <FieldText
                  placeholder="Enter here ..."
                  label="Number"
                  register={register(
                    `professionalRegistrations.${index}.number`,
                  )}
                  error={errors.professionalRegistrations?.[index]?.number}
                />
              </div>
              <div className="col-md-6">
                <FieldText
                  placeholder="Enter here ..."
                  label="Authority"
                  register={register(
                    `professionalRegistrations.${index}.authority`,
                  )}
                  error={errors.professionalRegistrations?.[index]?.authority}
                />
              </div>
            </div>
          </Accordion>
        );
      })}
      <div className="row" data-testid="gender-and-ni-row">
        <div className="col-md-6" data-testid="gender-container">
          <Select
            placeholder="Enter here ..."
            label="Gender"
            options={genders.map((el) => ({ label: el, value: el }))}
            register={register('gender')}
            error={errors.gender}
            data-testid="gender-select"
          />
        </div>
        <div className="col-md-6" data-testid="ni-number-container">
          <FieldText
            type="text"
            placeholder="Enter here ..."
            label="NI Number"
            register={register('niNumber')}
            error={errors.niNumber}
            data-testid="ni-number-field"
          />
        </div>
      </div>
      <TitleCard data-testid="employment-title">Type of Employment</TitleCard>
      <RadioGroup
        register={register('currentlyEmployed')}
        name="currentlyEmployed"
        error={errors.currentlyEmployed}
        options={[
          {
            label: 'Employed',
            value: true,
          },
          {
            label: 'Not Employed',
            value: false,
          },
        ]}
        data-testid="employment-radio-group"
      />
      <div className="row" data-testid="employment-details-row">
        <div className="col-md-6" data-testid="department-select-container">
          <MultipleSelect
            options={specialtiesList.map((el) => ({ label: el, value: el }))}
            placeholder="Select department"
            label="Department / Specialty"
            register={register('departments')}
            // TODO: Anas to check error handling
            error={errors.departments ? errors.departments[0] : undefined}
            data-testid="department-select"
          />
        </div>
        <div className="col-md-6" data-testid="job-title-select-container">
          <Select
            placeholder="Select job title"
            label="Job title"
            options={jobLevelWithGrades.map((el) => ({
              label: el.level,
              value: el.level,
            }))}
            register={register('designation')}
            error={errors.designation}
            data-testid="job-title-select"
          />
        </div>
      </div>
      <div className="row" data-testid="grade-and-pay-row">
        <div className="col-md-6" data-testid="grade-select-container">
          <Select
            placeholder="Select grade"
            label="Grade"
            options={
              jobLevelWithGrades
                .find((el) => el.level === formValues.designation)
                ?.grades?.map((el) => ({ label: el, value: el })) ?? []
            }
            register={register('grade')}
            error={errors.grade}
            disabled={!formValues.designation}
            data-testid="grade-select"
          />
        </div>
        <div className="col-md-6" data-testid="pay-amount-container">
          <FieldText
            step={0.01}
            placeholder="Enter here ..."
            label="Pay Amount"
            type="number"
            register={register('payAmount')}
            error={errors.payAmount}
            data-testid="pay-amount-field"
          />
        </div>
      </div>
      <TitleCard data-testid="address-title">Address</TitleCard>
      <FieldText
        placeholder="Enter here ..."
        label="Street Address"
        register={register('address.street')}
        error={errors?.address?.street}
        data-testid="street-address-field"
      />
      <FieldText
        placeholder="Enter here ..."
        label="City"
        register={register('address.city')}
        error={errors?.address?.city}
        data-testid="city-field"
      />
      <div className="row" data-testid="address-location-row">
        <div className="col-md-6" data-testid="country-select-container">
          <Select
            placeholder="Select country"
            label="Country"
            options={countriesWithRegions.map((el) => ({
              label: el.country,
              value: el.country,
            }))}
            register={register('address.country')}
            error={errors?.address?.country}
            data-testid="country-select"
          />
        </div>
        <div className="col-md-6" data-testid="region-select-container">
          <Select
            placeholder="Select Region"
            label="Region"
            options={
              countriesWithRegions
                .find((el) => el.country === formValues.address?.country)
                ?.regions?.map((el) => ({
                  label: el,
                  value: el,
                })) ?? []
            }
            register={register('address.region')}
            error={errors?.address?.region}
            disabled={!formValues.address?.country}
            data-testid="region-select"
          />
        </div>
      </div>
      <div className="row" data-testid="county-and-zip-row">
        <div className="col-md-6" data-testid="county-select-container">
          <Select
            placeholder="Select County"
            label="County"
            options={
              regionsWithCounties
                .find((el) => el.region === formValues.address?.region)
                ?.counties?.map((el) => ({
                  label: el,
                  value: el,
                })) ?? []
            }
            register={register('address.county')}
            error={errors?.address?.county}
            disabled={!formValues.address?.region}
            data-testid="county-select"
          />
        </div>
        <div className="col-md-6" data-testid="postcode-container">
          <FieldText
            placeholder="Enter here ..."
            label="Postcode"
            register={register('address.zip')}
            error={errors?.address?.zip}
            data-testid="postcode-field"
          />
        </div>
      </div>
      <TitleCard data-testid="payroll-title">Payroll</TitleCard>
      <RadioGroup
        name="payroll"
        options={[
          {
            label: 'Paye',
            value: 'paye',
          },
        ]}
        data-testid="payroll-radio-group"
      />
      <Select
        placeholder="Select Payroll"
        label="Payee options"
        options={payrollsList.map((el) => ({
          label: el.payrollProviderName,
          value: el._id,
        }))}
        register={register('payrollProviderId')}
        error={errors.payrollProviderId}
        data-testid="payroll-select"
      />
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={onCancel}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="create-button"
        >
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
