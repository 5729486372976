import React, { useEffect, useState } from 'react';
import { TitleCard, RadioGroup, Switch, Button } from '../../ui';
import {
  Compliance,
  ComplianceFile,
  ComplianceMetaData,
  HostedFile,
} from '../../../backend/careo-api';
import ComplianceUpload from '../compliance-edit/compliance-upload';
import { toast } from 'react-toastify';

type OccupationHealthClearanceProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: ComplianceMetaData,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey =
  | 'fitnessToWorkCertificateFile'
  | 'bloodsFile'
  | 'medicalQuestionnaireFile';

type TAllFields = TFilesKey | 'medicalTestsList';

const testOptions = [
  'BCG',
  'HEP B Antibody',
  'HEP B Antigen',
  'HEP C',
  'HIV',
  'Measles',
  'Mumps',
];

export const OccupationHealthClearance = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: OccupationHealthClearanceProps) => {
  const [isApproved, setIsApproved] = useState<boolean>();
  const [selectedTests, setSelectedTests] = useState<string[]>([]);

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; data: ComplianceFile[] }[]
  >([
    { key: 'fitnessToWorkCertificateFile', data: [] },
    { key: 'bloodsFile', data: [] },
    { key: 'medicalQuestionnaireFile', data: [] },
  ]);

  const onUpdateCompliance = (
    key: TAllFields,
    value: any,
    successMessage?: string,
  ) => {
    onClickSubmit(
      compliance._id,
      {
        [key]: value,
      },
      false,
      successMessage,
    );
  };

  const validateByAI = () => {
    const getActiveFilesByKey = (key: TFilesKey) => {
      return (
        filesList
          .find((el) => el.key === key)
          ?.data.filter((el) => !el.archiveDate) || []
      );
    };

    const activeDbsFiles = getActiveFilesByKey('fitnessToWorkCertificateFile');
    if (!activeDbsFiles.length) {
      toast.error('Please upload the Fitness to Work Certificate');
      return;
    }

    onClickSubmit(compliance._id, {}, true);
  };

  useEffect(() => {
    if (compliance?._id) {
      setSelectedTests(compliance.metadata?.medicalTestsList ?? []);
      setFilesList([
        {
          key: 'fitnessToWorkCertificateFile',
          data: compliance?.metadata?.fitnessToWorkCertificateFile ?? [],
        },
        {
          key: 'bloodsFile',
          data: compliance?.metadata?.bloodsFile ?? [],
        },
        {
          key: 'medicalQuestionnaireFile',
          data: compliance?.metadata?.medicalQuestionnaireFile ?? [],
        },
      ]);
      setIsApproved(compliance.isApproved);
    }
  }, [compliance]);

  return (
    <>
      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Fitness to Work Certificate
        </TitleCard>
        <ComplianceUpload
          fileKey="fitnessToWorkCertificateFile"
          label="Upload File"
          description=""
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
      </>
      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Bloods
        </TitleCard>
        <ComplianceUpload
          fileKey="bloodsFile"
          label="Upload File"
          description=""
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
      </>
      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Medical Questionnaire
        </TitleCard>
        <ComplianceUpload
          fileKey="medicalQuestionnaireFile"
          label="Upload File"
          description=""
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
      </>

      <>
        {testOptions.map((el) => {
          const isSelected = selectedTests.some((option) => option === el);
          return (
            <div className="medical-option-container">
              <div>{el}</div>
              <div data-testid={`medical-option-switch-${el}`}>
                <Switch
                  value={isSelected}
                  onClick={() => {
                    let newList = selectedTests;
                    if (isSelected) {
                      newList = newList.filter((item) => item !== el);
                    } else {
                      newList.push(el);
                    }
                    onUpdateCompliance('medicalTestsList', newList);
                    setSelectedTests(newList);
                  }}
                />
              </div>
            </div>
          );
        })}
      </>
      {/* Approved Section */}
      <>
        <TitleCard data-testid="title-card-approved">Approved</TitleCard>
        <RadioGroup
          options={[
            {
              label: 'Pass',
              value: 'true',
            },
            {
              label: 'Fail',
              value: 'false',
            },
          ]}
          name="approved"
          value={isApproved?.toString()}
          onChange={(value: any) => onUpdateValidation(compliance._id, value)}
          data-testid="approved-radio-group"
        />
        <div
          className="info-card compliance-status buttons-container validated-by-ai"
          data-testid="validate-by-ai-button-container"
        >
          <Button
            type="primary"
            onClick={validateByAI}
            data-testid="validate-by-ai-button"
          >
            Validate By AI
          </Button>
        </div>
      </>
    </>
  );
};
