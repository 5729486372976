import React, { useEffect, useMemo, useState } from 'react';
import { TitleCard, Button, FieldText, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { Application, ValidatePlacementDto } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  PlacementValidateSchema,
} from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

type NewPlacementFormProps = {
  application: Application;
  onCancel: () => void;
  getPlacementDetails: any;
};

export const ValidatePlacementForm = ({
  application,
  onCancel,
  getPlacementDetails,
}: NewPlacementFormProps) => {
  const {
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
    register,
    reset,
  } = useForm<ValidatePlacementDto>({
    resolver: yupResolver(PlacementValidateSchema),
    defaultValues: {
      coreRate: application.job.corePrice,
      fee: application.job.charge,
      onCallActiveRate: application.job.onCallActivePrice,
      onCallPassiveRate: application.job.onCallPassivePrice,
      unSocialRate: application.job.unSocialPrice,
      onCallFee: 0,
      vat: 0,
    },
  });

  const formValues = watch();

  const onClickClose = () => {
    onCancel();
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();
    AxiosInstance.applications
      .applicationsControllerValidatePlacement(application._id, {
        fee: Number(values.fee),
        vat: Number(values.vat),
        onCallFee: Number(values.onCallFee),
        onCallActiveRate: Number(values.onCallActiveRate),
        onCallPassiveRate: Number(values.onCallPassiveRate),
        coreRate: Number(values.coreRate),
        unSocialRate: Number(values.unSocialRate),
      })
      .then(() => {
        getPlacementDetails();
        toast.success('Placement validated successfully');
        onClickClose();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    reset({ fee: application.fee });
  }, []);

  const warnings = useMemo(() => {
    const {
      coreRate,
      fee,
      onCallActiveRate,
      onCallFee,
      onCallPassiveRate,
      unSocialRate,
    } = formValues;

    const {
      corePrice,
      charge,
      onCallActivePrice,
      onCallPassivePrice,
      unSocialPrice,
    } = application.job;
    return {
      coreRate:
        corePrice > coreRate
          ? `Note: value in vacancy is ${corePrice}`
          : undefined,
      fee: charge > fee ? `Note: value in vacancy is ${charge}` : undefined,
      onCallActiveRate:
        onCallActivePrice > onCallActiveRate
          ? `Note: value in vacancy is ${onCallActivePrice}`
          : undefined,
      // onCallFee: corePrice > coreRate
      //     ? `Note: value in vacancy is ${corePrice}`
      //     : undefined,
      onCallPassiveRate:
        onCallPassivePrice > onCallPassiveRate
          ? `Note: value in vacancy is ${onCallPassivePrice}`
          : undefined,
      unSocialRate:
        unSocialPrice > unSocialRate
          ? `Note: value in vacancy is ${unSocialPrice}`
          : undefined,
    };
  }, [formValues, application]);

  return (
    <FormContainer>
      <TitleCard data-testid="placement-charges-title">Charges</TitleCard>
      <FieldText
        placeholder="Enter here ..."
        label="Charge (per hour)"
        required
        register={register('fee', { required: true })}
        type="currency"
        min={0}
        error={errors.fee}
        data-testid="charge-per-hour"
        warning={warnings.fee}
      />
      <FieldText
        placeholder="Enter here ..."
        label="On Call Charge (per hour)"
        required
        register={register('onCallFee', { required: true })}
        type="currency"
        min={0}
        error={errors.onCallFee}
        data-testid="on-call-fee"
      />
      <FieldText
        placeholder="Enter here ..."
        label="VAT"
        required
        register={register('vat', { required: true })}
        type="currency"
        min={0}
        error={errors.vat}
        data-testid="vat"
      />

      <TitleCard data-testid="placement-charges-title">Rates</TitleCard>
      <FieldText
        placeholder="Enter here ..."
        label="Core Rate (per hour)"
        required
        register={register('coreRate', { required: true })}
        type="currency"
        min={0}
        error={errors.coreRate}
        data-testid="on-call-rate"
        warning={warnings.coreRate}
      />

      <FieldText
        placeholder="Enter here ..."
        label="Unsocial Rate (per hour)"
        required
        register={register('unSocialRate', { required: true })}
        type="currency"
        min={0}
        error={errors.unSocialRate}
        warning={warnings.unSocialRate}
        data-testid="on-call-rate"
      />

      <FieldText
        placeholder="Enter here ..."
        label="On Call Active Rate (per hour)"
        required
        register={register('onCallActiveRate', { required: true })}
        type="currency"
        min={0}
        error={errors.onCallActiveRate}
        warning={warnings.onCallActiveRate}
        data-testid="on-call-active-rate"
      />

      <FieldText
        placeholder="Enter here ..."
        label="On Call Passive Rate (per hour)"
        required
        register={register('onCallPassiveRate', { required: true })}
        type="currency"
        min={0}
        error={errors.onCallPassiveRate}
        warning={warnings.onCallPassiveRate}
        data-testid="on-call-passive-rate"
      />

      <div className="form-actions">
        <Button
          onClick={onClickClose}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="validate-button"
        >
          Validate
        </Button>
      </div>
    </FormContainer>
  );
};
