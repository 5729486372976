import React, { useState } from 'react';
import { Button, FieldText, FormContainer } from '../ui';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  RenameDocumentValidator,
} from '../../utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { handleFormsServerErrors } from '../../utils';
import { HostedFile } from '../../backend/careo-api';

type NewRenameDocumentFormProps = {
  document: HostedFile;
  onCancel: () => void;
  onSuccess: (_id: string, newFileName: string) => void;
};

export const RenameDocumentForm = ({
  document,
  onSuccess,
  onCancel,
}: NewRenameDocumentFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<{ newName: string }>({
    resolver: yupResolver(RenameDocumentValidator),
  });

  const fileName = document?.fileName
    ? document.fileName.substring(0, document.fileName.lastIndexOf('.')) ||
      document.fileName
    : '';

  const extension = document?.fileName
    ? document.fileName.substring(document.fileName.lastIndexOf('.') + 1) || ''
    : '';

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    const newFileName = `${values.newName}${extension ? '.' + extension : ''}`;

    AxiosInstance.files
      .filesControllerRenameCandidateFile(document._id, newFileName)
      .then(() => {
        toast.success('Document renamed successfully');
        onSuccess(document._id, `${values.newName}`);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  return (
    <FormContainer data-testid="form-container">
      <FieldText
        placeholder="Enter here ..."
        label={`Old Name (.${extension})`}
        required
        value={fileName}
        disabled
        data-testid="old-file-name"
      />
      <FieldText
        placeholder="Enter here ..."
        label={`New Name (.${extension})`}
        required
        register={register('newName', { required: true })}
        error={errors.newName}
        data-testid="new-file-name"
      />
      <div className="form-actions">
        <Button
          onClick={onCancel}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="rename-button"
        >
          Rename
        </Button>
      </div>
    </FormContainer>
  );
};
