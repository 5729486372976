import React, { ChangeEvent, useRef } from 'react';
import styled from 'styled-components';
import {
  TUIType,
  TUIVariant,
  breakpoint,
  getTypeStyles,
} from '../../constants';
import { UploadIcon } from '../../icons';

const ButtonContainer = styled.button<{
  type: TUIType;
  variant: TUIVariant;
}>`
  padding: 15px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  font-size: 14px;
  outline: unset !important;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  ${(props) => getTypeStyles(props.type, props.variant)}
  &:not(.disabled):hover {
    cursor: pointer;
    opacity: 0.9;
  }

  &:not(.disabled):active {
    top: 2px;
  }

  &.disabled {
    cursor: not-allowed !important;
    color: gray !important;
    border: none !important;
    background-color: #e9ecef !important;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    padding: 10px 10px;
    font-size: 10px;
    gap: 5px;
  }
`;

type ButtonProps = {
  type?: TUIType;
  variant?: TUIVariant;
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  'data-testid': string;
};

export const Button: React.FC<ButtonProps> = ({
  onClick = () => {},
  children,
  type = 'primary',
  variant = 'contained',
  disabled,
  className = '',
  'data-testid': testId,
}) => {
  return (
    <ButtonContainer
      onClick={onClick}
      type={type}
      className={`button ${className}  ${disabled ? 'disabled' : ''}`}
      variant={variant}
      disabled={disabled}
      data-testid={testId}
    >
      {children}
    </ButtonContainer>
  );
};

type UploadButtonProps = {
  onUpload: (file: File) => void;
  children: React.ReactNode;
  accept?: string;
  'data-testid': string;
};

export const UploadButton = ({
  onUpload,
  children,
  accept = '*',
  'data-testid': testId,
}: UploadButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onUpload(file);
    }
    event.target.value = '';
  };

  return (
    <div>
      <Button
        type="primary"
        variant="outlined"
        onClick={handleButtonClick}
        data-testid={`${testId}-upload-button`}
      >
        <UploadIcon data-testid={`${testId}-upload-icon`} /> {children}
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept={accept}
        data-testid={`${testId}-file-input`}
      />
    </div>
  );
};
