import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import type { UpdateComplianceDto } from '../../backend/careo-api';
import { EComplianceName } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  CreateComplianceListDtoSchema,
  handleFormsServerErrors,
} from '../../utils';
import {
  Button,
  FormContainer,
  FieldText,
  TitleCard,
  RadioGroup,
  Table,
  Checkbox,
} from '../ui';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const complianceTasksList = Object.values(EComplianceName);

type AddComplianceFormProps = {
  selectedTasks?: EComplianceName[];
  onCancel: () => void;
  onSuccess: () => void;
};

export const AddComplianceForm = ({
  selectedTasks = [],
  onCancel,
  onSuccess,
}: AddComplianceFormProps) => {
  const { id } = useParams();

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<UpdateComplianceDto>({
    resolver: yupResolver(CreateComplianceListDtoSchema as any),
  });

  const [selectedTasksList, setSelectedTasksList] =
    useState<EComplianceName[]>(selectedTasks);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickCheckboxAll = (value: boolean) => {
    setSelectedTasksList((prev) => {
      if (value) {
        return [...complianceTasksList.map((el) => el)];
      } else return [];
    });
  };

  const onClickCheckbox = (id: EComplianceName, value: boolean) => {
    setSelectedTasksList((prev) => {
      if (value) {
        prev.push(id);
      } else {
        prev = prev.filter((el) => el !== id);
      }
      return [...prev];
    });
  };

  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    AxiosInstance.compliances
      .compliancesControllerSetCompliancesByCandidateId(id!, {
        compliances: selectedTasksList.map((el) => ({
          taskName: el,
          reminderDate: values.reminderDate,
        })),
      })
      .then(() => {
        toast.success('Compliance updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  return (
    <FormContainer data-testid="form-container" className="compliance-form">
      <FieldText
        placeholder="Enter here ..."
        label={'Reminder Date'}
        type="date"
        register={register('reminderDate', { required: true })}
        error={errors.reminderDate}
        data-testid="reminder-date-field"
      />
      <TitleCard data-testid="payroll-title">Level</TitleCard>
      <RadioGroup
        className="level-options"
        options={[
          {
            label: 'Doctor',
            value: 'doctor',
          },
          {
            label: 'Non Clinical',
            value: 'non_clinical',
          },
          {
            label: 'Nurse',
            value: 'nurse',
          },
          {
            label: 'Healthcare Assistant',
            value: 'healthcare_assistant',
          },
        ]}
        onChange={(value) => {
          console.log(value);
        }}
        name={'complianceType'}
      />
      <Table>
        <thead>
          <tr>
            <th className="checkbox-table" data-testid="checkbox-header">
              <Checkbox
                checked={
                  !!selectedTasksList.length &&
                  selectedTasksList.length === complianceTasksList.length
                }
                indeterminate={
                  !!selectedTasksList.length &&
                  selectedTasksList.length < complianceTasksList.length
                }
                onChange={(e) => onClickCheckboxAll(e.target.checked)}
                data-testid="checkbox-all"
              />
            </th>
            <th>Select All</th>
          </tr>
        </thead>
        <tbody>
          {complianceTasksList.map((task) => (
            <tr key={task}>
              <td>
                <input
                  type="checkbox"
                  onChange={(e) => onClickCheckbox(task, e.target.checked)}
                  checked={!!selectedTasksList.find((el) => el === task)}
                  data-testid={`checkbox-input-${task}`}
                />
              </td>
              <td>{task}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={onCancel}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="update-button"
        >
          Confirm
        </Button>
      </div>
    </FormContainer>
  );
};
