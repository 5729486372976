import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  ActivityProjectDto,
  AddActivitiesProjectDto,
  Project,
  RoleActivityProjectDto,
} from '../../../backend/careo-api';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { useModal } from '../../../contexts/side-modal.context';
import { Button, FieldText, Select } from '../../ui';
import {
  LeftArrowIcon,
  MinusIcon,
  PlusIcon,
  RightArrowIcon,
} from '../../../icons';
import { Accordion } from '../../ui/accordion';
import {
  activityProjectSchema,
  AxiosInstance,
  AxiosInstanceErrorResponse,
  handleFormsServerErrors,
} from '../../../utils';
import { toast } from 'react-toastify';
import {
  projectActivityRateTypeList,
  projectActivityTypeList,
  subSpecialtiesList,
} from '../../../constants';

type ActivitiesFormStepProps = {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  createdProject: Project;
  setCreatedProject: Dispatch<SetStateAction<Project | undefined>>;
  getProjects: () => void;
};

const emptyRoleActivityObject: RoleActivityProjectDto = {
  grade: '' as any,
  level: '' as any,
  startTime: '',
  endTime: '',
  rate: 0,
  expanse: 0,
};

const emptyActivityObject: ActivityProjectDto = {
  subSpecialty: '' as any,
  activityType: '' as any,
  startDate: '',
  endDate: '',
  rateType: '' as any,
  rate: 0,
  template: 0,
  bcv: 0,
  clientDepartmentId: '',
  roles: [emptyRoleActivityObject],
};

export const ActivitiesFormStep = ({
  setStep,
  createdProject,
  setCreatedProject,
  getProjects,
}: ActivitiesFormStepProps) => {
  const { closeModal } = useModal();

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<AddActivitiesProjectDto>({
    resolver: yupResolver(activityProjectSchema as any),
  });

  const formValues = watch();

  const {
    fields: activities,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'activities',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const addActivity = async () => {
    append(emptyActivityObject, {
      focusName: `activities.${activities.length}.subSpecialty`,
    });
  };

  const addRole = (activityIndex: number) => {
    // const activitiesCopy = [...activities];
    // activitiesCopy[activityIndex].roles?.push(emptyRoleActivityObject);
    // reset({ activities: activitiesCopy });
  };

  const removeActivity = (activityIndex: number) => {
    remove(activityIndex);
  };

  const onSubmitActivityProject = async (isWithExist = false) => {
    setIsSubmitting(true);
    const values = getValues();

    const requestDto: AddActivitiesProjectDto = {
      activities: values.activities.map((el) => ({
        ...el,
        roles: el.roles,
      })),
    };

    await AxiosInstance.projects
      .projectsControllerAddActivitiesProjectId(createdProject._id, requestDto)
      .then((response) => {
        getProjects();
        setCreatedProject(response.data);
        if (isWithExist) {
          closeModal();
        } else {
          setStep((prev) => prev + 1);
        }
        toast.success('Financial Project saved successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (createdProject.activities.length) {
      reset({
        activities: createdProject.activities,
      });
    } else {
      reset({
        activities: [emptyActivityObject],
      });
    }
  }, [createdProject]);

  return (
    <>
      {activities.map((activity, activityIndex) => (
        <Accordion
          title={`Activity #${activityIndex + 1}`}
          className="primary-item"
          key={activityIndex}
          data-testid={`accordion-item-${activityIndex}`}
          isOpenByDefault={true}
        >
          <Accordion
            title={`Overview`}
            className="secondary-item"
            key={activityIndex}
            data-testid={`accordion-item-${activityIndex}`}
            isOpenByDefault={true}
          >
            <div className="row" data-testid={`overview-row-${activityIndex}`}>
              <div
                className="col-md-6"
                data-testid={`sub-specialty-select-${activityIndex}`}
              >
                <Select
                  placeholder="Enter here ..."
                  label="Sub specialty"
                  required
                  register={register(
                    `activities.${activityIndex}.subSpecialty`,
                  )}
                  error={errors.activities?.[activityIndex]?.subSpecialty}
                  options={subSpecialtiesList.map((el) => ({
                    label: el,
                    value: el,
                  }))}
                  control={control}
                />
              </div>
              <div
                className="col-md-6"
                data-testid={`activity-type-select-${activityIndex}`}
              >
                <Select
                  placeholder="Enter here ..."
                  label="Activity type"
                  required
                  register={register(
                    `activities.${activityIndex}.activityType`,
                  )}
                  error={errors.activities?.[activityIndex]?.activityType}
                  options={projectActivityTypeList.map((el) => ({
                    label: el,
                    value: el,
                  }))}
                  control={control}
                />
              </div>
              <div
                className="col-md-6"
                data-testid={`start-date-select-${activityIndex}`}
              >
                <FieldText
                  placeholder="Enter here ..."
                  label="Activity Start Date"
                  required
                  register={register(`activities.${activityIndex}.startDate`)}
                  error={errors.activities?.[activityIndex]?.startDate}
                  type="date"
                />
              </div>
              <div
                className="col-md-6"
                data-testid={`end-date-select-${activityIndex}`}
              >
                <FieldText
                  placeholder="Enter here ..."
                  label="Activity End Date"
                  required
                  register={register(`activities.${activityIndex}.endDate`)}
                  error={errors.activities?.[activityIndex]?.endDate}
                  type="date"
                />
              </div>
              <div className="col-md-12">
                <Select
                  placeholder="Enter here ..."
                  label="Activity Contact"
                  data-testid={`activity-contact-select-${activityIndex}`}
                  options={createdProject.client?.departments.map((el) => ({
                    label: el.firstNameContact + ' ' + el.lastNameContact,
                    value: el._id,
                  }))}
                  required
                  register={register(
                    `activities.${activityIndex}.clientDepartmentId`,
                  )}
                  error={errors.activities?.[activityIndex]?.clientDepartmentId}
                  control={control}
                />
              </div>
              <div
                className="col-md-3"
                data-testid={`type-select-${activityIndex}`}
              >
                <Select
                  placeholder="Enter here ..."
                  label="Type"
                  required
                  register={register(`activities.${activityIndex}.rateType`)}
                  error={errors.activities?.[activityIndex]?.rateType}
                  options={projectActivityRateTypeList.map((el) => ({
                    label: el,
                    value: el,
                  }))}
                  control={control}
                />
              </div>
              <div
                className="col-md-3"
                data-testid={`rate-field-${activityIndex}`}
              >
                <FieldText
                  label="Rate"
                  placeholder="Enter here ..."
                  required
                  register={register(`activities.${activityIndex}.rate`)}
                  error={errors.activities?.[activityIndex]?.rate}
                  type="currency"
                />
              </div>
              <div
                className="col-md-3"
                data-testid={`template-field-${activityIndex}`}
              >
                <FieldText
                  label="Template"
                  placeholder="Enter here ..."
                  required
                  register={register(`activities.${activityIndex}.template`)}
                  error={errors.activities?.[activityIndex]?.template}
                  type="currency"
                />
              </div>
              <div
                className="col-md-3"
                data-testid={`bcv-field-${activityIndex}`}
              >
                <FieldText
                  label="BCV"
                  placeholder="Enter here ..."
                  required
                  register={register(`activities.${activityIndex}.bcv`)}
                  error={errors.activities?.[activityIndex]?.bcv}
                  type="currency"
                />
              </div>
            </div>
          </Accordion>

          <Accordion
            title={`Roles/Placements`}
            className="secondary-item"
            key={activityIndex}
            data-testid={`accordion-roles-${activityIndex}`}
          >
            {/* {activity.roles?.map((role, roleIndex) => (
              <div
                key={`${activityIndex}-${roleIndex}`}
                data-testid={`role-${activityIndex}-${roleIndex}`}
              >
                <div
                  className="row"
                  data-testid={`role-row-${activityIndex}-${roleIndex}`}
                >
                  <div
                    className="col-md-6"
                    data-testid={`role-select-${activityIndex}-${roleIndex}`}
                  >
                    <Select
                      placeholder="Select job title"
                      label="Job title"
                      options={jobLevelWithGrades.map((el) => ({
                        label: el.level,
                        value: el.level,
                      }))}
                      register={register(
                        `activities.${activityIndex}.roles.${roleIndex}.level`,
                      )}
                      error={
                        errors.activities?.[activityIndex]?.roles?.[roleIndex]
                          ?.level
                      }
                      data-testid="job-title-select"
                      control={control}
                    />
                  </div>
                  <div
                    className="col-md-6"
                    data-testid={`role-select-${activityIndex}-${roleIndex}`}
                  >
                    <Select
                      placeholder="Select grade"
                      label="Grade"
                      options={
                        jobLevelWithGrades
                          .find(
                            (el) =>
                              el.level ===
                              formValues.activities[activityIndex].roles[
                                roleIndex
                              ].level,
                          )
                          ?.grades?.map((el) => ({ label: el, value: el })) ??
                        []
                      }
                      register={register(
                        `activities.${activityIndex}.roles.${roleIndex}.grade`,
                      )}
                      error={
                        errors.activities?.[activityIndex]?.roles?.[roleIndex]
                          ?.grade
                      }
                      disabled={
                        !formValues.activities[activityIndex].roles[roleIndex]
                          .level
                      }
                      data-testid="grade-select"
                      control={control}
                    />
                  </div>
                  <div
                    className="col-md-6"
                    data-testid={`timings-select-${activityIndex}-${roleIndex}`}
                  >
                    <FieldText
                      placeholder="Enter here ..."
                      label="Timings"
                      required
                      register={register(
                        `activities.${activityIndex}.roles.${roleIndex}.startTime`,
                      )}
                      error={
                        errors.activities?.[activityIndex]?.roles?.[roleIndex]
                          ?.startTime
                      }
                      type="time"
                    />
                  </div>
                  <div
                    className="col-md-6"
                    data-testid={`empty-select-${activityIndex}-${roleIndex}`}
                  >
                    <FieldText
                      placeholder="Enter here ..."
                      label="&nbsp;"
                      register={register(
                        `activities.${activityIndex}.roles.${roleIndex}.endTime`,
                      )}
                      error={
                        errors.activities?.[activityIndex]?.roles?.[roleIndex]
                          ?.endTime
                      }
                      type="time"
                    />
                  </div>
                  <div
                    className="col-md-6"
                    data-testid={`role-rate-select-${activityIndex}-${roleIndex}`}
                  >
                    <FieldText
                      placeholder="Enter here ..."
                      label="Rate per placement"
                      required
                      register={register(
                        `activities.${activityIndex}.roles.${roleIndex}.rate`,
                      )}
                      error={
                        errors.activities?.[activityIndex]?.roles?.[roleIndex]
                          ?.rate
                      }
                      type="currency"
                    />
                  </div>
                  <div
                    className="col-md-6 d-flex align-items-center justify-content-between"
                    data-testid={`role-expenses-${activityIndex}-${roleIndex}`}
                  >
                    <FieldText
                      placeholder="Enter here ..."
                      label="Expenses budget per placement"
                      required
                      register={register(
                        `activities.${activityIndex}.roles.${roleIndex}.expanse`,
                      )}
                      error={
                        errors.activities?.[activityIndex]?.roles?.[roleIndex]
                          ?.expanse
                      }
                      type="currency"
                    />
                    <div
                      className="action-container"
                      data-testid={`role-delete-${activityIndex}-${roleIndex}`}
                    >
                      <div>&nbsp;</div>
                      <div
                        className="delete-icon"
                        onClick={() => {}}
                        data-testid={`role-delete-icon-${activityIndex}-${roleIndex}`}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))} */}
            <Button
              type="success"
              onClick={() => addRole(activityIndex)}
              data-testid={`add-role-button-${activityIndex}`}
              variant="outlined"
            >
              <PlusIcon /> Add another role
            </Button>
          </Accordion>

          <Button
            type="danger"
            onClick={() => removeActivity(activityIndex)}
            data-testid={`remove-activity-button-${activityIndex}`}
          >
            <MinusIcon /> Remove this activity
          </Button>
        </Accordion>
      ))}

      <Button
        type="success"
        onClick={() => addActivity()}
        data-testid="add-activity-button"
        variant="outlined"
      >
        <PlusIcon /> Add Another Activity
      </Button>

      <div className="form-actions stepper" data-testid="form-actions">
        <div className="left-container">
          <Button
            onClick={handleSubmit(() => onSubmitActivityProject(true))}
            type="primary"
            variant="outlined"
            data-testid="save-exit-button"
          >
            Save & Exit
          </Button>
          <Button
            type="danger"
            variant="outlined"
            onClick={() => closeModal()}
            data-testid="cancel-button"
          >
            Close
          </Button>
        </div>
        <div className="right-container">
          <Button onClick={() => setStep(1)} data-testid="back-button">
            <LeftArrowIcon /> Back
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit(() => onSubmitActivityProject(false))}
            data-testid="next-button" // Added test ID for Next button
            disabled={isSubmitting}
          >
            Next <RightArrowIcon />
          </Button>
        </div>
      </div>
    </>
  );
};
